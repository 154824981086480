import React, { useEffect, useState, useCallback } from "react";
// import fileDownload from "js-file-download";
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Button,
} from "reactstrap";
// core components
import { dashboardPieChart, dashboardLineChart, getPieChartColor } from "variables/charts.js";
import { useLocation, useParams } from "react-router";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isBrowser, isSafari } from "react-device-detect";
import Finance from "../services/FinanceByItem";
import api from "services/api";
import fileDownload from "js-file-download";
import { auth } from "../services/Firebase";
import "../styles/card.css";

function ItemDashboard() {
  const location = useLocation();
  const { title: itemTitle } = location.state.item;
  const { itemId } = useParams();
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [netTotalPercentage, setNetTotalPercentage] = useState(0);
  const [netZeeploPercentage, setZeeploPercentage] = useState(0);
  const [netTaxPercentage, setTaxPercentage] = useState(0);
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const iconsColor = 'text-zeeplo-icons';

  const downloadCSV = useCallback(async () => {
    setIsCsvLoading(true);

    try {
      const token = await auth.currentUser.getIdToken(true);

      const params = {
        token,
        id: itemId
      }

      if(initialDate && finalDate) {
        params.initial_date = moment(initialDate).format("DD-MM-YYYY");
        params.final_date = moment(finalDate).format("DD-MM-YYYY");
      }

      const response = await api.get("/csvByItem", {
        params,
      });
      fileDownload(response.data, `${itemTitle}.csv`);
    } catch (error) {
      console.log(error);
    } finally {
      setIsCsvLoading(false);
    }
  }, [itemId, itemTitle, initialDate, finalDate]);

  const getItemInfo = useCallback(
    async (initial_date, final_date) => {
      try {
        setIsLoading(true);

        const financeSingleton = new Finance().getInstance();
        const financeByItem = await financeSingleton.getFinanceByItem(
          itemId,
          initial_date,
          final_date
        );
        setItem(financeByItem);
        setNetTotalPercentage(
          (
            (Number(financeByItem?.net_total) /
              (Number(financeByItem?.net_total) +
                Number(financeByItem?.zeeplo_total) +
                Number(financeByItem?.tax_total))) *
            100
          ).toFixed(2)
        );
        setZeeploPercentage(
          (
            (Number(financeByItem?.zeeplo_total) /
              (Number(financeByItem?.net_total) +
                Number(financeByItem?.zeeplo_total) +
                Number(financeByItem?.tax_total))) *
            100
          ).toFixed(2)
        );
        setTaxPercentage(
          (
            (Number(financeByItem?.tax_total) /
              (Number(financeByItem?.net_total) +
                Number(financeByItem?.zeeplo_total) +
                Number(financeByItem?.tax_total))) *
            100
          ).toFixed(2)
        );

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    },
    [itemId]
  );

  useEffect(() => {
    document.querySelector(".navbar-brand").innerText = itemTitle;
    getItemInfo();
  }, [getItemInfo, itemId, itemTitle]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (initialDate && finalDate) {
      const data = {
        initial_date: moment(initialDate).format("DD-MM-YYYY"),
        final_date: moment(finalDate).format("DD-MM-YYYY"),
      };

      getItemInfo(data.initial_date, data.final_date);
    } else {
      getItemInfo();
    }
  };

  const changeInitialDate = (e) => {
    setInitialDate(e.target.value);
  };

  const changeFinalDate = (e) => {
    setFinalDate(e.target.value);
  };

  const calculatePercentage = (numbers, index) => {
    let total = 0;
    numbers.forEach(element => {
      total += element.data;
    });
    return (numbers[index].data * 100 / total).toFixed(2);
  };

  if (isLoading) {
    return (
      <div className="content">
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <>
    {/* Checking which dashboard is visible, v2 or v1 */}
    {item.new_version ? 
      // Dashboard v2
      <div className="content">
        <Form onSubmit={submitHandler}>
          <Card>
            <CardBody>
              <Row>
                <Col lg="4" md="4" sm="12" xs="12">
                  <FormGroup>
                    <label className="card-text">Data de Inicio:</label>
                    {isSafari && isBrowser ? (
                      <DatePicker
                        selected={initialDate}
                        onChange={setInitialDate}
                        customInput={<Input />}
                      />
                    ) : (
                      <Input
                        name="initial_date"
                        value={initialDate}
                        onChange={changeInitialDate}
                        type="date"
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  <FormGroup>
                    <label className="card-text">Data de Fim:</label>
                    {isSafari && isBrowser ? (
                      <DatePicker
                        selected={finalDate}
                        onChange={setFinalDate}
                        customInput={<Input />}
                      />
                    ) : (
                      <Input
                        name="final_date"
                        value={finalDate}
                        onChange={changeFinalDate}
                        type="date"
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  <br />
                  <Button
                    className="mb-0 btn-round btn-zeeplo-orange"
                    type="submit"
                  >
                    Consultar
                  </Button>
                  <Button
                    className="mb-0 btn-round btn-margin-left"
                    color="z-purple-1"
                    onClick={downloadCSV}
                    disabled={isCsvLoading}
                    outline
                  >
                    {isCsvLoading ? "Loading..." : <><i class="fa fa-file-export"></i> Exportar</>} 
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>

        <Row>
          <Col lg="4" md="4" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className={`nc-icon nc-money-coins ${iconsColor}`} />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category">Receita gerada</p>
                      <CardTitle tag="p">
                        <span style={{ fontSize: 25 }}>
                          {item?.net_items_price?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </span>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="4" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-money-coins ${iconsColor}`} />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category">Ganho Líquido</p>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {item?.net_items_price_comission?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="4" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className={`nc-icon nc-refresh-69 ${iconsColor}`} />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category">
                      Reembolsadas
                      </p>
                      <CardTitle tag="p">
                        <span style={{ fontSize: 25 }}>
                        {item?.refunded}
                        </span>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="3" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className={`nc-icon nc-chart-bar-32 ${iconsColor}`} />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category">
                        Vendas
                      </p>
                      <CardTitle tag="p">
                        <span style={{ fontSize: 20 }}>
                          {item?.total_count}
                        </span>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className={`nc-icon nc-paper ${iconsColor}`} />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category">
                        Em Aberto
                      </p>
                      <CardTitle tag="p">
                        <span style={{ fontSize: 20 }}>
                        {item?.billet_printed}
                        </span>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className={`nc-icon nc-calendar-60 ${iconsColor}`} />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category">
                        Expiradas
                      </p>
                      <CardTitle tag="p">
                        <span style={{ fontSize: 20 }}>
                        {item?.expired}
                        </span>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="3" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className={`nc-icon nc-alert-circle-i ${iconsColor}`} />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category">
                        Canceladas
                      </p>
                      <CardTitle tag="p">
                        <span style={{ fontSize: 20 }}>
                        {item?.canceled}
                        </span>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* <Col lg="3" md="3" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-alert-circle-i text-zeeplo-blue" />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category">
                        Abandonadas
                      </p>
                      <CardTitle tag="p">
                        <span style={{ fontSize: 20 }}>
                        {item?.abandoned}
                        </span>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
        <Row>
          <Col md="6" className="d-flex" style={{ flex: 1 }}>
            <Card className="w-100">
              <CardHeader>
                <CardHeader>
                  <CardTitle tag="h5">Distribuição</CardTitle>
                </CardHeader>
                <CardBody>
                  <Pie
                    data={
                      dashboardPieChart(item?.pieChart, item.new_version).data
                    }
                    options={
                      dashboardPieChart().options
                    }
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="legend">
                  {item?.pieChart
                      .map((slice, index) => {
                        return <>
                          <div>
                              <i className={`fa fa-circle text-zeeplo-${getPieChartColor(index)}`} /> {slice.label ? slice.label : 'Outros'}
                              <br></br> <i className={`fa fa-circle text-zeeplo-${getPieChartColor(index)}`} />
                              <span>
                                {" "}
                                {slice.data}
                                {"  - "}
                                {calculatePercentage(item?.pieChart, index) + "%"}
                              </span>
                            </div>
                        <br></br>
                        </>
                      })
                  }
                  </div>
                </CardFooter>
              </CardHeader>
            </Card>
          </Col>
          <Col
            md="6"
            className="d-flex"
            style={{
              flex: 1,
            }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <CardTitle tag="h5">Evolução Diária</CardTitle>
              </CardHeader>
              <CardBody>
                <Line
                  data={dashboardLineChart(item.labels, item.values).data}
                  options={dashboardLineChart(item.labels, item.values).options}
                  width={400}
                  height={200}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div> 
      : 
      // Dashboard v1
      <div className="content">
        <Form onSubmit={submitHandler}>
          <Card>
            <CardBody>
              <Row>
                <Col lg="4" md="4" sm="12" xs="12">
                  <FormGroup>
                    <label className="card-text">Data de Inicio:</label>
                    {isSafari && isBrowser ? (
                      <DatePicker
                        selected={initialDate}
                        onChange={setInitialDate}
                        customInput={<Input />}
                      />
                    ) : (
                      <Input
                        name="initial_date"
                        value={initialDate}
                        onChange={changeInitialDate}
                        type="date"
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  <FormGroup>
                    <label className="card-text">Data de Fim:</label>
                    {isSafari && isBrowser ? (
                      <DatePicker
                        selected={finalDate}
                        onChange={setFinalDate}
                        customInput={<Input />}
                      />
                    ) : (
                      <Input
                        name="final_date"
                        value={finalDate}
                        onChange={changeFinalDate}
                        type="date"
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  <br />
                  <Button
                    className="mb-0 btn-zeeplo-orange"
                    color="primary"
                    type="submit"
                  >
                    Consultar
                  </Button>
                  <Button
                    primary
                    className="mb-0  btn-margin-left"
                    onClick={downloadCSV}
                    disabled={isCsvLoading}
                  >
                    {isCsvLoading ? "Loading..." : <><i class="fa fa-file-export"></i> Exportar</>} 
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
        <Row>
          <Col lg="6" md="8" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className={`nc-icon nc-money-coins ${iconsColor}`} />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category card-text">Faturamento</p>
                      <CardTitle tag="p">
                        <span style={{ fontSize: 20 }}>
                          {" "}
                          {item?.gross_total?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </span>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" md="8" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className={`nc-icon nc-chart-bar-32 ${iconsColor}`} />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category card-text">
                        Número total de vendas
                      </p>
                      <CardTitle tag="p">
                        {" "}
                        <span style={{ fontSize: 20 }}>
                          {item?.total_count}
                        </span>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6" className="d-flex" style={{ flex: 1 }}>
            <Card className="w-100">
              <CardHeader>
                <CardHeader>
                  <CardTitle tag="h5">Distribuição</CardTitle>
                </CardHeader>
                <CardBody>
                  <Pie
                    data={
                      dashboardPieChart([
                        { data: item.tax_total, label: "Taxas de Transação", hexaColor: "#404040", backgroundColor: "#404040" },
                        { data: item.zeeplo_total, label: "Zeeplo", hexaColor: "#B0B0B0",backgroundColor: "#B0B0B0" },
                        { data: item.net_total, label: "Líquido", hexaColor: "#f37029",backgroundColor: "#f37029" }]
                      ).data
                    }
                    options={
                      dashboardPieChart().options
                    }
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="legend">
                  {
                    [{ data: item.net_total, label: "Líquido", backgroundColor: "orange", percentage: netTotalPercentage},
                    { data: item.zeeplo_total, label: "Zeeplo", backgroundColor: "light-gray", percentage: netZeeploPercentage },
                    { data: item.tax_total, label: item.hasFixedFee ? "Juros pagos pelo cliente" : "Taxa de transação", backgroundColor: "dark-gray", percentage: netTaxPercentage }]
                      .map(slice => {
                        return <>
                          <div>
                          <i className={`fa fa-circle text-zeeplo-${slice.backgroundColor}`}/> {slice.label}
                          <br></br> <i className={`fa fa-circle text-zeeplo-${slice.backgroundColor}`} />
                          <span>
                            {" "}
                            {slice.data?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                            {"  - "}
                            {slice.percentage + "%"}
                          </span>
                        </div>
                        <br></br>
                        </>
                      })
                  }
                  </div>
                </CardFooter>
              </CardHeader>
            </Card>
          </Col>
          <Col
            md="6"
            className="d-flex"
            style={{
              flex: 1,
            }}
          >
            <Card className="card-chart w-100">
              <CardHeader>
                <CardTitle tag="h5">Evolução Diária</CardTitle>
              </CardHeader>
              <CardBody>
                <Line
                  data={dashboardLineChart(item.labels, item.values).data}
                  options={dashboardLineChart(item.labels, item.values).options}
                  width={400}
                  height={200}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      }
      
    </>
  );
}

export default ItemDashboard;
