import { auth } from "./Firebase";
import api from "./api"

class Vendor {
  constructor() {
    this.token = null;
    this.vendor = null;
  }

  getIdToken = async () => {
    const token = await auth.currentUser.getIdToken(true);
    this.token = token
  }

  getVendor = async () => {
    if (!this.vendor) {
      if (!this.token) {
        await this.getIdToken()
      }
      const response = await api.get("/vendor", {
        params: {
          token: this.token,
        },
      });
      this.vendor = response.data;
    }
    return this.vendor;
  }

  setVendor = (vendor) => {
    this.vendor = vendor;
  }

}

export default class VendorSingleton {
  constructor() {
    if (!VendorSingleton.instance) {
      VendorSingleton.instance = new Vendor();
    }
  }

  getInstance() {
    return VendorSingleton.instance;
  }
}