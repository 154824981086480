import axios from "axios";

const api = axios.create({
  baseURL: "https://api.telegram.org/bot5177675946:AAHMg3PxVeKu0Wa4Q3fZgvbO-caend3Z068",
  headers: { Accept: "application/json" },
});

class TelegramBot {
  requestRefund = async text => {
    const response = await api.post("/sendMessage", {
      chat_id: -810618152,
      text,
      parse_mode: "HTML",
    });
    return response.data;
  }
}

export default class TelegramBotSingleton {
  constructor() {
    if (!TelegramBotSingleton.instance) {
      TelegramBotSingleton.instance = new TelegramBot();
    }
  }

  getInstance() {
    return TelegramBotSingleton.instance;
  }
}