
import FinanceByVendor from '../services/FinanceByVendor'
import FinanceByItem from '../services/FinanceByItem'
import OrdersByVendor from '../services/OrdersByVendor'
import OrdersByItem from '../services/OrdersByItem'
import Finance from '../services/Finance'
import Sales from '../services/Sales'
import Items from '../services/Items'
import Subscriptions from '../services/Subscription'
import Roles from '../services/Roles'


const clearSingletons = () => {
    OrdersByItem.instance = undefined;
    OrdersByVendor.instance = undefined;
    FinanceByItem.instance = undefined;
    FinanceByVendor.instance = undefined;
    Finance.instance = undefined;
    Sales.instance = undefined;
    Items.instance = undefined;
    Subscriptions.instance = undefined;
    Roles.instance = undefined;
};

export default clearSingletons;