import React, { useState, useCallback, useRef } from "react";
import { useLocation, useRouteMatch } from "react-router";
import NotificationAlert from "react-notification-alert";
import {
  Badge,
  Button,
  Col,
  Input,
  FormGroup,
  Row,
  Card,
  CardBody,
  Form,
  Label,
  Modal,
} from "reactstrap";
import moment from "moment";
import "../styles/input.css";
import { useHistory } from "react-router-dom";
import { translateOrderStatus } from 'variables/orderStatusPtBr';
import TelegramBotSingleton from "services/TelegramBot";
import { auth } from "services/Firebase";
import api from "services/api";

function Order() {
  const location = useLocation();
  const { item, itemName, prevPath } = location.state;
  const { url } = useRouteMatch();
  const history = useHistory();
  const [modalClassic, setModalClassic] = useState(false);
  const [refundReason, setRefundReason] = useState("");
  const [refundReasonState, setRefundReasonState] = useState("");
  const [refundRequested, setRefundRequested] = useState(item.refundRequested);
  const [isRefundLoading, setIsRefundLoading] = useState(false);
  const [partnerValue] = useState(
    (item.item_price - item.zeeplo_fee - item.comission)
  );
  const [modalBilletPix, setModalBilletPix] = useState(false);
  const notificationAlert = useRef();

  const checkPaymentMethod = paymentMethod => {
    if (paymentMethod === 'boleto') {
      history.push({ pathname: `${url}/refund`, state: { item, prevPath } });
    } else {
      toggleModalClassic();
    }
  };

  const toggleModalClassic = useCallback(() => {
    setModalClassic(!modalClassic);
  }, [modalClassic]);

  const toggleModalBilletPix = useCallback(() => {
    setModalBilletPix(!modalBilletPix);
  }, [modalBilletPix]);

  const storeRefundedOrder = () => {
    let data = localStorage.getItem('refundedOrder');
    if (data) {
      data = JSON.parse(data);
    } else {
      data = {};
    }
    data[item.id] = true;
    localStorage.setItem('refundedOrder', JSON.stringify(data));
  }

  const getOrderStatusLabel = status => {
    let color = '';
    switch (status) {
      case 'canceled':
        color = 'danger';
        break;
      case 'approved':
        color = 'success';
        break;
      case 'expired':
        color = 'danger';
        break;
      case 'abandoned':
        color = 'danger';
        break;
      case 'billet_printed':
        color = 'warning';
        break;
      case 'waiting_payment':
        color = 'warning';
        break;
      case 'refunded':
        color = 'warning';
        break;
      case 'chargeback':
          color = 'warning';
          break;
      default:
        color = 'warning'
        break;
    }
    return color;
  }

  const refundOrder = useCallback(async () => {
    if (!refundReason || refundReason === "") {
      setRefundReasonState("has-danger");
      return;
    }
    setIsRefundLoading(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      await api.put("/requestRefund", {
        token,
        order_item_id: item.id,
        refund_reason: refundReason
      });
      const telegramBotSingleton = new TelegramBotSingleton().getInstance();
      await telegramBotSingleton.requestRefund(
        `\n<b>Reembolso</b>\n` +
        `\n<b>Data da venda</b>\n${item.formated_created_at}\n` +
        `\n<b>Email do cliente</b>\n${item.email}\n` +
        `\n<b>Valor</b>\n${item?.total_price.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}\n` +
        `\n<b>Motivo</b> \n${refundReason}\n` +
        `\n<a href="https://digitalmanager.guru/admin/transactions/${item.gateway}/${item.id}">Ver no Guru</a>`
      );
      // Workaround to deal with Telegram offline
      // setRefundRequested(true);
      // toggleModalClassic();
      // // store the order id to update status to refunded requested on sales screen
      // storeRefundedOrder();
    } catch (error) {
      console.log(error);
    } finally {
      setRefundRequested(true);
      toggleModalClassic();
      // store the order id to update status to refunded requested on sales screen
      storeRefundedOrder();
      setIsRefundLoading(false);
    }
  }, [item.email, item.formated_created_at, item.id, item.total_price, refundReason, toggleModalClassic]);

  const onChangeReason = (value) => {
    setRefundReason(value);
    setRefundReasonState("");
  };

  const notify = (place) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            Conteúdo copiado com sucesso.
          </div>
        </div>
      ),
      type: "success",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 2
    };
    notificationAlert.current.notificationAlert(options);
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    notify("tr");
  }

  return (
    <>
      <div className="content">
        <Col
          lg={{ size: 8, offset: 1 }}
          md={{ size: 10, offset: 1 }}
          sm={{ size: 10, offset: 1 }}
        >
          <Row>
            <h5 className="pl-3">
              Detalhes
            </h5>
          </Row>
          <Card>
            <CardBody>
              <Form className="form-horizontal">
                <Row>
                  <Label sm="2">ID da venda</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.id}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Status</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        <Badge color={refundRequested ? getOrderStatusLabel('') : getOrderStatusLabel(item?.status)} pill>
                          {refundRequested ? 'Reembolso solicitado' : translateOrderStatus(item?.status)}
                        </Badge>
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Data da venda</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.formated_created_at}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Valor do produto</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.item_price ? item.item_price.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }) : item?.total_price.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                {item.discount_codes &&  <Row>
                  <Label sm="2">Cupom</Label>
                  <Col sm="4">
                    <FormGroup>
                      <p className="form-control-static">
                        <Badge className="btn-zeeplo-blue" pill>
                          {item?.discount_codes[0].code}
                        </Badge>
                      </p>
                    </FormGroup>
                  </Col>
                  <Label sm="2">Valor</Label>
                  <Col sm="4">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.discount_codes[0].amount.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </p>
                    </FormGroup>
                  </Col>
                </Row> }
                <Row>
                  <Label sm="2">Produto</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {itemName}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Forma de pagamento</Label>
                  <Col sm="4">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.method === 'boleto' || item?.method === 'pix' ? 
                          <Button
                            className="btn-link btn-round btn-zeeplo-purple-1"
                            onClick={toggleModalBilletPix}
                          >
                            {item.method}
                          </Button> : 
                          <>{item?.method}</>
                        }
                      </p>
                    </FormGroup>
                  </Col>
                  <Label sm="2">Parcelas</Label>
                  <Col sm="4">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.installments}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Row>
            <h5 className="pl-3">
              Cliente
            </h5>
          </Row>
          <Card>
            <CardBody>
              <Form className="form-horizontal">
                <Row>
                  <Label sm="2">Nome</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.name}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Email</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.email}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Celular</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.phone}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">CPF</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.cpf}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Row>
            {(!refundRequested && item?.financial_status === 'paid') &&
              <Col className="ml-auto text-right" >
                <Button
                  className="btn-link"
                  color="danger"
                  onClick={() => checkPaymentMethod(item?.method)}
                >
                  Reembolsar venda
                </Button>
              </Col>
            }
          </Row>

          {item.item_price && <>
            <Row>
              <h5 className="pl-3">
                Comissão
              </h5>
            </Row>
            <Card>
              <CardBody>
                <Form className="form-horizontal">
                  <Row>
                    <Label sm="2">Valor do produto</Label>
                    <Col sm="10">
                      <FormGroup>
                        <p className="form-control-static">
                          {item?.item_price?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Taxas</Label>
                    <Col sm="10">
                      <FormGroup>
                        <p className="form-control-static">
                          {item?.zeeplo_fee?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Parceiros</Label>
                    <Col sm="10">
                      <FormGroup>
                        <p className="form-control-static">
                          {partnerValue.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Líquido</Label>
                    <Col sm="10">
                      <FormGroup>
                        <p className="form-control-static">
                          {item?.comission?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </>}
        </Col>
        <Row>
          <Col className="text-center" md="12">
            {/* Classic Modal */}
            <Modal isOpen={modalClassic} toggle={toggleModalClassic}>
              <div className="modal-header justify-content-center">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModalClassic}
                >
                  <i className="nc-icon nc-simple-remove" />
                </button>
                <h4 className="title title-up">Reembolsar</h4>
              </div>
              <div className="modal-body">
                <Card className="card-plain">
                  <Col>
                    <FormGroup className={`has-label ${refundReasonState}`}>
                      <label>Motivo *</label>
                      <Input
                        className="textarea"
                        type="textarea"
                        cols="80"
                        rows="4"
                        value={refundReason}
                        onChange={(e) => onChangeReason(e.target.value)}
                      />
                      {refundReasonState === "has-danger" ? (
                        <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                      ) : null}
                    </FormGroup>
                    <div className="category form-category">
                      * Obrigatório
                    </div>
                  </Col>
                </Card>
              </div>
              <div className="modal-footer">
                <div className="left-side">
                  <Button
                    className="btn-link"
                    color="default"
                    data-dismiss="modal"
                    type="button"
                    onClick={refundOrder}
                    disable={isRefundLoading}
                  >
                    {isRefundLoading ? "Aguarde..." : "Reembolsar"}
                  </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                  <Button
                    className="btn-link"
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalClassic}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Modal>
          </Col>
        </Row>
        {/* Billet and Pix Modal */}
        <Row>
          <Col className="text-center" md="12">
            {/* Classic Modal */}
            <Modal isOpen={modalBilletPix} toggle={toggleModalBilletPix}>
              <NotificationAlert ref={notificationAlert} />
              <div className="modal-header justify-content-center">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModalBilletPix}
                >
                  <i className="nc-icon nc-simple-remove" />
                </button>
                <h4 className="title title-up">{item?.method}</h4>
              </div>
              <div className="modal-body">
                <Card className="card-plain">
                  <Col>
                    {item.billet &&  <>
                      <FormGroup className={'has-label'}>
                          <label>Data de vencimento</label>
                          <p className="form-control-static">
                            <b>{moment(item?.billet.expiration_date).format("DD/MM/YYYY")}</b>
                          </p>
                        </FormGroup>
                        <FormGroup className={'has-label'}>
                          <label>Linha digitável</label>
                          <Input
                            defaultValue={item.billet.line}
                            disabled
                            type="text"
                          />
                          <Button
                            className="btn-round"
                            color="z-purple-1"
                            onClick={() => copyText(item.billet.line)}
                            outline
                          >
                            <><i class="fa fa-copy"></i> Copiar</>
                          </Button>
                        </FormGroup>
                        <FormGroup className={'has-label'}>
                          <label>Url do boleto</label>
                          <Input
                            defaultValue={item.billet.url}
                            disabled
                            type="text"
                          />
                          <Button
                            className="btn-round"
                            color="z-purple-1"
                            onClick={() => copyText(item.billet.url)}
                            outline
                          >
                            <><i class="fa fa-copy"></i> Copiar</>
                          </Button>
                        </FormGroup> 
                      </>
                    }
                    {item.pix &&  <>
                      <FormGroup className={'has-label'}>
                        <img
                          alt="..."
                          src={item.pix.qrcode.url}
                        />
                      </FormGroup>
                      <FormGroup className={'has-label'}>
                        <label>Data de expiração</label>
                        <p className="form-control-static">
                          <b>{moment(item.pix.expiration_date).format("DD/MM/YYYY")}</b>
                        </p>
                      </FormGroup>
                      <FormGroup className={'has-label'}>
                        <label>Pix copia e cola</label>
                        <Input
                          defaultValue={item.pix.qrcode.signature}
                          disabled
                          type="text"
                        />
                        <Button
                          className="btn-round"
                          color="z-purple-1"
                          onClick={() => copyText(item.pix.qrcode.signature)}
                          outline
                        >
                          <><i class="fa fa-copy"></i> Copiar</>
                        </Button>
                      </FormGroup>
                    </>}
                  </Col>
                </Card>
              </div>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Order;
