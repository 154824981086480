import { auth } from "./Firebase";
import api from "./api"

class Finance {
  constructor() {
    this.token = null;
    this.financeData = null;
  }

  getIdToken = async () => {
    const token = await auth.currentUser.getIdToken(true);
    this.token = token
  }

  getFinanceData = async () => {

    if (!this.financeData) {

      if (!this.token) {
        await this.getIdToken()
      }

      const response = await api.get("/finance", {
        params: {
          token: this.token,
        },
      });
      this.financeData = response.data;
      
      return response.data;
    }
    return this.financeData;
  }

  requestTransfer = async (requestedValue, bankAccount) => {

    if (!this.token) {
      await this.getIdToken()
    }

    const response = await api.post("/requestTransfer", bankAccount, {
      params: {
        token: this.token,
        requested_value: requestedValue
      },
    });
    this.financeData = response.data;
    
    return response.data;
  }

}

export default class FinanceSingleton {
  constructor() {
    if (!FinanceSingleton.instance) {
      FinanceSingleton.instance = new Finance();
    }
  }

  getInstance() {
    return FinanceSingleton.instance;
  }
}