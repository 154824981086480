import React, { useState, useEffect } from "react";
import {
  Badge,
  Tooltip,
  Col,
  Row,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Progress, 
  Button
} from "reactstrap";
import './styles.css';
import SubscriptionsSingleton from "../../services/Subscription";
import moment from "moment";
require('moment/locale/pt-br');



function SubscriptionOverview() {
  const [isLoading, setIsLoading] = useState(true);
  const [vendor, setVendor] = useState({
    newSubscriptions: 0,
    activesSubscriptions: 0,
    canceledSubscriptions: 0,
    overdueSignatures: 0,
    percentageRevenueValue: 0.00,
    percentageRevenue: 0,
    expectedGrossRevenue: 0.00,
    predictedGrossValue: 0.00
  })
  // const [initialDate, setInitialDate] = useState("");
  // const [finalDate, setFinalDate] = useState("");

  const [tooltip, setTooltip] = useState({
    newSubscriptions: false,
    activesSubscriptions: false,
    canceledSubscriptions: false,
    overdueSignatures: false,
  });
  const [totalForecast, setTotalForecast] = useState(0); 
  const iconsColor = 'text-zeeplo-icons';
 useEffect(() => {
  const subscriptionsSingleton = new SubscriptionsSingleton().getInstance();
    async function getSubscriptions() {
      try {
        const subscriptions = await subscriptionsSingleton.getSubscriptions();
        setVendor(() => ({
          newSubscriptions: subscriptions.news,
          activesSubscriptions: subscriptions.active,
          canceledSubscriptions: subscriptions.canceled,
          overdueSignatures: subscriptions.pastdue,
          percentageRevenue: subscriptions.progressPercentage,
          percentageRevenueValue: subscriptions.amountReceived,
          predictedGrossValue: subscriptions.amountReceivable,
        }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
    async function getTotalForecast() {
      try {
      const forecast = await subscriptionsSingleton.getTotalForecast();
      setTotalForecast(forecast);
      } catch (error) {}
    }
    getSubscriptions();
    getTotalForecast();
  }, []);


  // const changeInitialDate = (e) => {
  //   setInitialDate(e.target.value);
  // };

  // const changeFinalDate = (e) => {
  //   setFinalDate(e.target.value);
  // };

  const setTooltipEvent = (e) => {
    const id = e.target.id;
    setTooltip(() => ({
      [id]: !tooltip[id],
    }));
  };

  if (isLoading) {
    return (
      <div className="content">
        <div>Loading...</div>
      </div>
    );
  }
  
  return (
    <>
    <div className="content">
          {/* <Form >
          <Card>
              <CardBody>
                <Row>
                  <Col lg="4" md="4" sm="12" xs="12">
                    <FormGroup>
                      <label className="card-text">Data de Inicio:</label>
                      {isSafari && isBrowser ? (
                        <DatePicker
                          selected={initialDate}
                          onChange={setInitialDate}
                          customInput={<Input />}
                        />
                      ) : (
                        <Input
                          name="initial_date"
                          value={initialDate}
                          onChange={changeInitialDate}
                          type="date"
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4" md="4" sm="12" xs="12">
                    <FormGroup>
                      <label className="card-text">Data de Fim:</label>
                      {isSafari && isBrowser ? (
                        <DatePicker
                          selected={finalDate}
                          onChange={setFinalDate}
                          customInput={<Input />}
                        />
                      ) : (
                        <Input
                          name="final_date"
                          value={finalDate}
                          onChange={changeFinalDate}
                          type="date"
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4" md="4" sm="12" xs="12">
                    <br />
                    <Button
                      className="mb-0 btn-round btn-zeeplo-orange"
                      type="submit"
                    >
                      Consultar
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form> */}
            <Row>
            <h5 className="pl-3">
              Receita Total Prevista
            </h5>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-left icon-warning">
                        <i className={`nc-icon nc-money-coins ${iconsColor}`} />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category">Saldo Total</p>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 28 }}>
                            {Number(totalForecast).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </span>
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row>
                    <Col sm="7">
                      <div className="stats">{moment().format('[Hoje às] HH:mm')}</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right" style={{visibility: 'hidden' }} >
                        <Button
                          className="btn-round btn-icon btn-zeeplo-blue"
                          color="warning"
                          size="sm"
                        >
                          <i className="nc-icon nc-simple-add" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <h5 className="pl-3">
              Receita Prevista para o Mês (MRR)
            </h5>
          </Row>
          <Form>
             <Card>
             <CardBody>
                <Row>
                    <Col lg="4" md="4" sm="12" xs="12">
                        <div className="numbers pull-left"> {vendor?.percentageRevenueValue?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })} <span className="badge">{vendor?.percentageRevenue + "%"}</span></div>
                    </Col>
                    <Col lg="4" md="4" sm="12" xs="12">
                        <div className="big-title">RECEITA LÍQUIDA PARA O MÊS DE {moment().format('MMMM').toLocaleUpperCase()}</div>
                    </Col>
                    <Col lg="4" md="4" sm="12" xs="12">
                        <div className="numbers pull-right"> {vendor?.predictedGrossValue?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}</div>
                    </Col>
                </Row>
                <Row>
                <Col >
                <Progress max="100" value={vendor?.percentageRevenue} barClassName="progress-bar"  />
                  </Col>
                </Row> 
                </CardBody>
                <CardFooter>
                      <hr />
                      <div className="stats">{ moment().format('[Hoje às] HH:mm')}</div>
                 </CardFooter>  
              </Card>
          </Form>
          <Row>
            <h5 className="pl-3">
             Assinaturas
            </h5>
          </Row>
          <Row>
            <Col lg="3" md="3" sm="12" xs="12"> 
            <Card className="card-stats">
                <CardBody>
                     <Row>
                     <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-chart-bar-32 ${iconsColor}`} />
                      </div>
                     </Col>
                     <Col md="9" xs="8">
                     <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                         Novas{" "}
                          <Badge className="btn-zeeplo-light-gray" pill id="newSubscriptions">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={tooltip.newSubscriptions} target="newSubscriptions" toggle={setTooltipEvent}>
                           Novas assinaturas no período informado
                        </Tooltip>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {vendor?.newSubscriptions}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                     </Col>
                     </Row>
                </CardBody>  
            </Card>
            </Col>   
            <Col lg="3" md="3" sm="12" xs="12">   
            <Card className="card-stats">
                <CardBody>
                     <Row>
                     <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-chart-bar-32 ${iconsColor}`} />
                      </div>
                     </Col>
                     <Col md="9" xs="8">
                     <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                          Ativas{" "}
                          <Badge className="btn-zeeplo-light-gray" pill id="activesSubscriptions">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={tooltip.activesSubscriptions} target="activesSubscriptions" toggle={setTooltipEvent}>
                           Assinaturas em dia
                        </Tooltip>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {vendor?.activesSubscriptions}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                     </Col>
                     </Row>
                </CardBody>  
            </Card>
            </Col>  
            <Col lg="3" md="3" sm="12" xs="12"> 
            <Card className="card-stats">
                <CardBody>
                     <Row>
                     <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-chart-bar-32 ${iconsColor}`} />
                      </div>
                     </Col>
                     <Col md="9" xs="8">
                     <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                         Canceladas{" "}
                          <Badge className="btn-zeeplo-light-gray" pill id="canceledSubscriptions">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={tooltip.canceledSubscriptions} target="canceledSubscriptions" toggle={setTooltipEvent}>
                          Total de assinaturas canceladas no período informado
                        </Tooltip>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {vendor?.canceledSubscriptions}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                     </Col>
                     </Row>
                </CardBody>  
            </Card>  
            </Col>  
            <Col lg="3" md="3" sm="12" xs="12">   
            <Card className="card-stats">
                <CardBody>
                     <Row>
                     <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-chart-bar-32 ${iconsColor}`} />
                      </div>
                     </Col>
                     <Col md="9" xs="8">
                     <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                         Atrasadas{" "}
                          <Badge className="btn-zeeplo-light-gray" pill id="overdueSignatures">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={tooltip.overdueSignatures} target="overdueSignatures" toggle={setTooltipEvent}>
                           Assinaturas atrasadas que ainda não foram canceladas
                        </Tooltip>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {vendor?.overdueSignatures}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                     </Col>
                     </Row>
                </CardBody>  
            </Card>
            </Col>    
          </Row>
    </div>
    </>
  );
}

export default SubscriptionOverview;