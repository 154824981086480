import { auth } from "./Firebase";
import api from "./api"

class OrdersByVendor {
  constructor() {
    this.token = null;
    this.initial_date = null;
    this.final_date = null;
    this.dashboard = []
  }

  getIdToken = async () => {
    const token = await auth.currentUser.getIdToken(true);
    this.token = token
  }

  getOrdersByVendor = async (initial_date, final_date) => {
    if (this.initial_date !== initial_date && this.final_date !== final_date) {
      if (!this.token) {
        await this.getIdToken()
      }
      const response = await api.get("/ordersByVendor", {
        params: {
          token: this.token,
          initial_date,
          final_date,
        },
      });
      this.initial_date = initial_date;
      this.final_date = final_date;
      this.orders = response.data;
      return response.data;
    }
    return this.orders;
  }
}

export default class OrdersByVendorSingleton {
  constructor() {
    if (!OrdersByVendorSingleton.instance) {
      OrdersByVendorSingleton.instance = new OrdersByVendor();
    }
  }

  getInstance() {
    return OrdersByVendorSingleton.instance;
  }
}