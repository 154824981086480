import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import {
  Col,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import Items from "../services/Items"

function TransactionPerItem() {
  const { url } = useRouteMatch();
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [input, setInput] = useState("");

  useEffect(() => {
    async function getItemsInfo() {
      try {
        document.querySelector(".navbar-brand").innerText = "Listagem De Pedidos";

        const itemsSingleton = new Items().getInstance();
        const itemList = await itemsSingleton.getItems()

        setItems(itemList);
        setFilteredItems(itemList);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
    getItemsInfo();
  }, []);

  const removeSpecialChar = (str) =>
    str.normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");

  if (isLoading) {
    return (
      <div className="content">
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <>
      <div className="content">
        <Col
          lg={{ size: 8, offset: 1 }}
          md={{ size: 10, offset: 1 }}
          sm={{ size: 10, offset: 1 }}
        >
          <InputGroup>
            <Input
              onChange={(e) => {
                setInput(e.target.value);
              }}
              id="text"
              style={{ margin: "10px 0px" }}
            />

            <InputGroupAddon addonType="append">
              <Button
                className="btn-zeeplo-orange"
                onClick={() => {
                  setFilteredItems(
                    items.filter((item) =>
                      removeSpecialChar(item.title.toLowerCase()).includes(
                        removeSpecialChar(input.toLowerCase())
                      )
                    )
                  );
                }}
              >
                Pesquisar
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <ListGroup>
            {filteredItems.map((item) => (
              <Link
                to={{ pathname: `${url}/${item.id}`, state: { item } }}
                key={item.id}
                style={{ color: "#66615B", width: "100%" }}
              >
                <ListGroupItem>{item.title}</ListGroupItem>
              </Link>
            ))}
          </ListGroup>
        </Col>
      </div>
    </>
  );
}

export default TransactionPerItem;
