/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Form,
  FormGroup

} from "reactstrap";
import Select from "react-select";
import routes from "../../routes";

import { useFirebase } from "react-redux-firebase";
import clearSingletons from "util/logout";
import "../../styles/navbar.css";
import { auth } from "../../services/Firebase";
import api from "../../services/api";

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [color, setColor] = useState("transparent");
  const [product, setProduct] = useState();
  const [productList, setProductList] = useState([]);
  const [isViewAsList, setIsViewAsList] = useState(false);
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  
  
  //const lcation = useLocation();
  const firebase = useFirebase();
  const sidebarToggle = React.createRef();

  useEffect(() => {
    async function getProductList() {
      try {
          const token = await auth.currentUser.getIdToken(true);
          const response = await api.get('/findAllVendor', {
            params: { token: token },
          });

          setProductList(response.data.map((item) => {
            if (item.activer) {
              setProduct({ value: item.vendor, label: item.vendor });
            }
          
            if(response.data.length > 0) {
              setIsViewAsList(true);
            }
            return { value: item.vendor, label: item.vendor };
          }));

        } catch (error) {
          console.log(error);
      }
    }
    getProductList();
  }, []);

  const updateProduct = async (product) => {
    try {
      setIsSelectLoading(true);
      const token = await auth.currentUser.getIdToken(true);
      const response = await api.get('/updateVendorViewAs', {
        params: { token: token,  viewas: product.value }
      });
      if (response.status === 200) {
        setIsSelectLoading(false);
        setProduct(product);
        window.location.reload();
      }
    } catch (error) {
      setIsSelectLoading(false);
      console.log(error);
    }

  }

  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };

  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };

  const getBrand = () => {
    let brandName = "Default Brand";
    routes.some((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
        return true;
      } else if (prop?.collapse) {
        brandName = prop.name;
        return true;
      }
      return false;
    });
   
    return brandName;
  };

  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={
        props.location.pathname.indexOf("full-screen-maps") !== -1
          ? "dark"
          : color
      }
      expand="lg"
      className={
        props.location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top"
          : "navbar-absolute fixed-top " +
            (color === "transparent" ? "navbar-transparent " : "")
      }
    >
      <Container fluid>
        <div className="navbar-wrapper navbar-text-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand className="navbar-text" href="/">
            {getBrand()}
          </NavbarBrand>
        </div>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        {isViewAsList ? <Form>
          <FormGroup>
            <Select
              styles={{
                control: (base) => ({
                  ...base,
                  width: '300px',
                })
              }}
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Produtor"
              name="singleSelect"
              closeMenuOnSelect={false}
              value={product}
              onChange={(value) => {
                updateProduct(value);
              }}
              isLoading={isSelectLoading}
              options={productList}
            />
          </FormGroup>
        </Form> : null}
       
        <Collapse
          isOpen={isOpen}
          navbar
          className="justify-content-end flex-stay"
        >
          <Nav navbar>
            <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={(e) => dropdownToggle(e)}
            >
              <DropdownToggle caret nav>
                <i className="nc-icon nc-circle-10" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => {
                            clearSingletons();
                            firebase.logout();
                        }
                    } tag="a">
                  Sair
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
