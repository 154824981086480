/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// javascript plugin used to create scrollbars on windows
import { Route, Switch } from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import RolesSingleton from "../services/Roles";
import routes from "routes.js";

function Dashboard(props) {
  const [restrict, setRestrict] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);

  const getRoles = async () => {
    try {
      const roles = new RolesSingleton().getInstance();
      const rolesData = await roles.getRoles();
      setRestrict(rolesData.restrict);
      setIsLoading(false);
    } catch (error) { 
      setIsLoading(false);
      console.error('Failed to fetch roles', error);
    }
  }
  useEffect(() => {
    getRoles();
  }, []);

  const checkUserRole = (routes) => {
    if (isLoading) return [];
    if (restrict.length > 0) {
      return routes.filter(route => restrict.includes(route.name));
    }
    return routes;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      return (
      <Route
          exact
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
      />
      );
  });
};

  return (
    <div className="wrapper">
      <Sidebar {...props} routes={checkUserRole(routes)} />
      <div className="main-panel">
        <DemoNavbar {...props} />
        <Switch>
        <Switch>{getRoutes(routes)}</Switch>
        </Switch>
        <Footer fluid />
      </div>
    </div>
  );
  // }
}

export default Dashboard;
