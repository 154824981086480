/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useFirebase } from "react-redux-firebase";
import { useHistory, useLocation } from "react-router";
import { Container, Row, Col, Form, Card, CardHeader, CardBody, InputGroup, InputGroupAddon,InputGroupText,
  Input, FormGroup, Label, CardFooter, Button } from "reactstrap";

import Policy from "../../assets/pdf/Termos_de_uso_Zeeplo.pdf";
import './styles.css'; // Importando o arquivo CSS 
import Footer from "../footer/Footer";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [policy, setPolicy] = useState(true);
  const [loginError, setLoginError] = useState();
  const [loginExpiredToken, setLoginExpiredToken] = useState();

  const firebase = useFirebase();
  const history = useHistory();
  const location = useLocation();

  let { from } = location.state || { from: { pathname: "/admin/dashboard" } };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("redirect")) {
      setLoginExpiredToken(localStorage.getItem('expiredToken'));
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    firebase
      .login({
        email,
        password,
      })
      .then((response) => {
        localStorage.setItem('userEmail', response.user.user.email);
        localStorage.removeItem('expiredToken');
        history.replace(from);
      })
      .catch((error) => {
        setLoginError(true);
      });
  };

  const forgotPassword = () => {
    const supportMessage = encodeURIComponent("Olá! Eu esqueci minha senha e preciso redefinir meu acesso. Você poderia me ajudar, por favor?");
    window.open("https://api.whatsapp.com/send?phone=++5521972208013&text=" + supportMessage, "_blank");
  }

  return (
    <div className="wrapper wrapper-full-page">
    <div className="full-page section-image">
    <div className="login-page">
       <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form action="" className="form" method="" onSubmit={onSubmit}>
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">Olá, somos a Zeeplo!</h3>
                  </CardHeader>
                </CardHeader>
                {loginError && (
                  <div className="alert alert-danger" role="alert">
                    Senha ou Email errados!!!
                  </div>
                )}
                {loginExpiredToken && (
                  <div className="alert alert-danger" role="alert">
                    Sessão Expirada.
                  </div>
                )}
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                    placeholder="Email" 
                    type="email"
                    onChange={(e) => setEmail(e.target.value)} />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Senha"
                      type="password"
                      autoComplete="off"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                  <br />
                  <div className="form-group">
                  <a style={{ color: "#66615B" }} href={Policy} download>
                    Termos de Uso
                  </a>
                </div>
                  <FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input 
                        onClick={(e) => setPolicy(!e.target.checked)}
                        type="checkbox" />
                        <span className="form-check-sign" />
                        Eu concordo com os termos de uso
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="btn-round mb-3 btn-zeeplo-orange"
                    disabled={policy}
                    type="submit"
                    >
                    Entrar
                  </Button>
                  <FormGroup className="text-center">
                  <a href="" onClick={() => forgotPassword()}>
                        Esqueceu a senha ?
                  </a>
                  </FormGroup>
                  <FormGroup className="text-center">
                    <Label>
                      <span className="text" /> Não tem uma conta?  {" "}
                      <a href="https://zeeplo.com.br/criar-conta/">
                        Cadastre-se
                      </a>
                    </Label>
                  </FormGroup>
                  
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
   
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/zeeplo_login_01.jpg")})`
        }}
      />
      </div>
      <Footer fluid />
    </div>
   
    </div>
    
  );
}

export default Login;
