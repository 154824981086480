import { auth } from "./Firebase";
import api from "./api"

class Items {
  constructor() {
    this.token = null;
    this.items = [];
  }

  getIdToken = async () => {
    const token = await auth.currentUser.getIdToken(true);
    this.token = token
  }

  getItems = async () => {
    if (!this.items.length) {

      if (!this.token) {
        await this.getIdToken()
      }
  
      const response = await api.get("/items", {
        params: {
          token: this.token,
        },
      });

      this.items = response.data;
      
      return response.data;
    }
    return this.items;
  }
}

export default class ItemsSingleton {
  constructor() {
    if (!ItemsSingleton.instance) {
      ItemsSingleton.instance = new Items();
    }
  }

  getInstance() {
    return ItemsSingleton.instance;
  }
}