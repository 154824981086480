import { auth } from "./Firebase";
import api from "./api"

class Roles {
    constructor() {
        this.roles = null;
        this.token = null;
    }

    getIdToken = async () => {
        const token = await auth.currentUser.getIdToken(true);
        this.token = token
    }

    getRoles = async () => {
        if (!this.roles) {
            if (!this.token) {
                await this.getIdToken()
            }
            const response = await api.get("/profileRoles", {
                params: {
                    token: this.token,
                },
            });
            this.roles = response.data;
        }
        return this.roles;
    }

}

export default class RolesSingleton {
    constructor() {
        if (!RolesSingleton.instance) {
            RolesSingleton.instance = new Roles();
        }
    }

    getInstance() {
        return RolesSingleton.instance;
    }
}