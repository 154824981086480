import React, { useState, useEffect, useCallback } from "react";
import { useRouteMatch } from "react-router";
import { CardHeader } from "reactstrap";
import {
  Badge,
  Button,
  Col,
  Input,
  FormGroup,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Collapse,
  Form,
  UncontrolledTooltip
} from "reactstrap";
import './styles.css';
import moment from "moment";
import Select from "react-select";
import { isBrowser, isSafari } from "react-device-detect";
import DatePicker from "react-datepicker";
import "../../styles/input.css";
import "../../styles/card.css";
import Subscription from "../../services/Subscription";
import { Link } from "react-router-dom";
import Items from "../../services/Items"
import fileDownload from "js-file-download";
import { auth } from "services/Firebase";
import api from "services/api";

function SubscriptionSales() {
  const NUMBER_OF_SUBSCRIPTIONS = 20;
  const { url } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [customerList, setCustomerList] = useState({ value: "name", label: "Nome" });
  const [customer, setCustomer] = useState('');
  const [status, setStatus] = useState([]);
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [qtdMinimum, setQtdMinimum] = useState("");
  const [qtdMaximum, setQtdMaximum] = useState("");
  const [isSalesLoading, setIsSalesLoading] = useState(false);
  const [subscriptionSales] = useState(new Subscription().getInstance());
  const [countSubscription, setCountSubscription] = useState(0);
  const [products, setProducts] = useState([]);
  const [totalSubscription, setTotalSubscription] = useState(0);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filters, setFilters] = useState({});
  
  
  

  const collapsesToggle = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const getSalesSubscriptions = useCallback(async filters => {
    try {
      setSubscriptionsData([]);
      setIsSalesLoading(true);
      const response = await subscriptionSales.getSalesSubscriptions(filters);
      setSubscriptionsData(response.subscriptions);
      setTotalSubscription(response.count);
      setCountSubscription(countSubscription + NUMBER_OF_SUBSCRIPTIONS);
      setIsLoading(false);
      setIsSalesLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsSalesLoading(false);
    }
  }, [countSubscription, subscriptionSales]);

  const submitHandler = (e) => {
    e.preventDefault();
    const newFilters = {};  
    if (customer) {
      newFilters.type = customerList.value;
      newFilters.customer = customer;
    }
   
    if (status && status.length > 0) {
      newFilters.status = status?.map(item => item.value);
    }
   
    if (initialDate) {
      const startDate = moment(initialDate);
      newFilters.initialDate = startDate.format('DD-MM-YYYY');
    }
    if (finalDate) {
      const endDate = moment(finalDate);
      newFilters.finalDate = endDate.format('DD-MM-YYYY');
    }
    if (qtdMinimum) {
      newFilters.qtdMinimum = qtdMinimum;
    }
    if (qtdMaximum) {
      newFilters.qtdMaximum = qtdMaximum;
    }
    newFilters.limit = NUMBER_OF_SUBSCRIPTIONS;
    setTotalSubscription(0);
    setIsFilterApplied(true);
    setFilters(newFilters);
    getSalesSubscriptions(newFilters);

  };
  const clearFilters = () => {
    setDefaultDate();
    setCustomerList({ value: "name", label: "Nome" });
    setCustomer("");
    setStatus([]);
    setQtdMinimum("");
    setQtdMaximum("");
    setIsFilterApplied(false);
    setFilters({});
  };

  const changeInitialDate = (e) => {
    setInitialDate(e.target.value);
  };

  const changeFinalDate = (e) => {
    setFinalDate(e.target.value);
  };

  useEffect(() => {
    setDefaultDate();
    getItemsInfo();
  }, []);

  async function getItemsInfo() {
    try {
      const itemsSingleton = new Items().getInstance();
      let itemList = await itemsSingleton.getItems();
      itemList = itemList
        .map(item => {
          return {
            value: item.id,
            label: item.title
          }
        });
      setProducts(itemList);
      const initialFilters = { ...subscriptionSales.getFilters() };
      setFilters(initialFilters);
      getSalesSubscriptions(initialFilters);
    } catch (error) {
      console.log(error);
    }
  }

  const setDefaultDate = () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    setInitialDate(firstDay.toISOString().slice(0, 10));
    setFinalDate(lastDay.toISOString().slice(0, 10));
  }

  function convertDate(obj) {
    if (obj && obj._seconds) {
      const timestamp = obj._seconds * 1000;
      const date = new Date(timestamp);
      return date.toLocaleString(); // Ou o formato desejado
  }
  return null;
}

const downloadCSV = async () => {
  setIsCsvLoading(true);
  try {
    const newFilters = {};  
    if (customer) {
      newFilters.type = customerList.value;
      newFilters.customer = customer;
    }
   
    if (status && status.length > 0) {
      newFilters.status = status?.map(item => item.value);
    }
   
    if (initialDate) {
      const startDate = moment(initialDate);
      newFilters.initialDate = startDate.format('DD-MM-YYYY');
    }
    if (finalDate) {
      const endDate = moment(finalDate);
      newFilters.finalDate = endDate.format('DD-MM-YYYY');
    }
    if (qtdMinimum) {
      newFilters.qtdMinimum = qtdMinimum;
    }
    if (qtdMaximum) {
      newFilters.qtdMaximum = qtdMaximum;
    }
    const token = await auth.currentUser.getIdToken(true);
    const responseVendor = await api.get("/vendor", { params: { token } });
    const response = await subscriptionSales.getSalesSubscriptionsCSV(newFilters);
    const vendor = responseVendor.data.vendor;
    fileDownload(
      response,
      `Zeeplo_${vendor}_${moment().format("DD/MM/YYYY_TH:m")}.csv`
    );
    setTotalSubscription(0);
    setIsFilterApplied(true);
    setFilters(newFilters);
  } catch (error) {
    console.log(error);
  } finally {
    setIsCsvLoading(false);
  }
};

const loadMore = useCallback(async () => {
  try {
    setIsSalesLoading(true);
    filters.startAfter = subscriptionsData[subscriptionsData.length - 1].formated_created_at;
    const response = await subscriptionSales.getSalesSubscriptions(filters);
    setSubscriptionsData([...subscriptionsData, ...response.subscriptions]);
    setIsSalesLoading(false);
  } catch (error) {
    console.log(error);
    setIsSalesLoading(false);
  }
}, [filters, subscriptionsData, subscriptionSales]);

  const getFaFaIcon = paymentMethod => {
    let icon = '';
    switch (paymentMethod) {
      case 'credit_card':
        icon = 'fa fa-credit-card';
        break;
      case 'billet':
        icon = 'fa fa-barcode';
        break;
      case 'pix':
        icon = 'fa fa-qrcode';
        break;
      case 'paypal':
        icon = 'fab fa-paypal';
        break;
      default:
        icon = 'far fa-money-bill-alt';
        break;
    }
    return icon;
  }

  if (isLoading) {
    return (
      <div className="content">
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className="content">
     <Col md="12">
      <Row>
        <h5 className="pl-3">
          Listagem
        </h5>
        <Col className="ml-auto text-right" >
          <Button
            className="btn-round"
            color="z-purple-1"
            onClick={downloadCSV}
            disabled={isCsvLoading}
            outline
          >
            {isCsvLoading ? "Carregando..." : <><i class="fa fa-file-export"></i> Exportar</>}
          </Button>
        </Col>
      </Row>
      </Col>
      <Col md="12">
        <Card>
          <CardBody>
            <div
              aria-multiselectable={true}
              className="card-collapse"
              id="accordion"
              role="tablist"
            >
              <Card className="card-plain">
                <CardHeader>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    aria-expanded={isFilterVisible}
                    href="#"
                    data-parent="#accordion"
                    data-toggle="collapse"
                    onClick={() => collapsesToggle()}
                  >
                    Filtros{" "}
                    <i className="nc-icon nc-minimal-down" />
                  </a>
                </CardHeader>
                <Collapse
                  role="tabpanel"
                  isOpen={isFilterVisible}>
                  <CardBody>
                    <div
                      aria-multiselectable={true}
                      className="card-collapse"
                      id="accordion"
                      role="tablist"
                    >
                      <Form onSubmit={submitHandler}>
                        <Row>
                          <Col lg="3" md="3" sm="12" xs="12">
                            <FormGroup>
                              <CardTitle>Cliente</CardTitle>
                              <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Cliente"
                                name="singleSelect"
                                closeMenuOnSelect={false}
                                value={customerList}
                                onChange={(value) => {
                                  setCustomerList(value);
                                  setCustomer("");
                                }}
                                options={[
                                  { value: "name", label: "Nome" },
                                  { value: "email", label: "E-mail" },
                                  { value: "cpf", label: "CPF/CNPJ" },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6" md="6" sm="12" xs="12">
                            <FormGroup>
                              <CardTitle> {customerList.label}</CardTitle>
                              <Input
                                placeholder={customerList.label}
                                value={customer}
                                onChange={e => setCustomer(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3" md="3" sm="12" xs="12">
                            <FormGroup>
                              <CardTitle>Status</CardTitle>
                              <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Todos os status"
                                name="multipleSelect"
                                closeMenuOnSelect={false}
                                isMulti
                                value={status}
                                onChange={(value) => setStatus(value)}
                                options={[
                                  { value: "active", label: "Ativos" },
                                  { value: "pastdue", label: "Atrasada" },
                                  { value: "canceled", label: "Cancelada" },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3" md="3" sm="12" xs="12">
                            <FormGroup>
                              <CardTitle>Data de Início</CardTitle>
                              {isSafari && isBrowser ? (
                                <DatePicker
                                  selected={initialDate}
                                  onChange={setInitialDate}
                                  customInput={<Input />}
                                />
                              ) : (
                                <Input
                                  name="initial_date"
                                  value={initialDate}
                                  onChange={changeInitialDate}
                                  type="date"
                                />
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="3" md="3" sm="12" xs="12">
                            <FormGroup>
                              <CardTitle>Data de Fim</CardTitle>
                              {isSafari && isBrowser ? (
                                <DatePicker
                                  selected={finalDate}
                                  onChange={setFinalDate}
                                  customInput={<Input />}
                                />
                              ) : (
                                <Input
                                  name="final_date"
                                  value={finalDate}
                                  onChange={changeFinalDate}
                                  type="date"
                                />
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="3" md="3" sm="6" xs="6">
                            <FormGroup>
                              <CardTitle>Qtd mínima de cobranças</CardTitle>
                              <Input
                                type="number"
                                placeholder={"0"}
                                value={qtdMinimum}
                                onChange={e => setQtdMinimum(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3" md="3" sm="6" xs="6">
                            <FormGroup>
                              <CardTitle>Qtd máxima de cobranças</CardTitle>
                              <Input
                                type="number"
                                placeholder={"0"}
                                value={qtdMaximum}
                                onChange={e => setQtdMaximum(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                         <Col >
                            <Button
                              className="btn-link btn-round btn-zeeplo-purple-1"
                              disabled={!isFilterApplied}
                              onClick={clearFilters}
                            >
                              Limpar filtros
                            </Button>
                          </Col>
                          <Col className="text-right">
                            <br />
                            <Button
                              className="mb-0 btn-round btn-zeeplo-orange btn-zeeplo-orange-subscription"
                              type="submit"
                              disabled={isSalesLoading}
                            >
                              {isSalesLoading ? "Loading..." : "Consultar"}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Collapse>
              </Card>
              <Card className="card-plain">
                <CardBody>
                <label className="card-text">{subscriptionsData.length} de {totalSubscription}</label>
                     <Table className="table-hover" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Cliente</th>
                            <th>Produto</th>
                            <th >Iniciada Em</th>
                            <th >QTD cobranças</th>
                            <th >Atualizada em</th>
                            <th className="text-center">Status</th>
                            <th className="text-right">Valor</th>
                            <th className="text-right"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {subscriptionsData?.length === 0 ? (isSalesLoading ? <tr><td>Carregando...</td></tr> : <tr><td>Nenhuma venda encontrada</td></tr>) :
                            subscriptionsData?.map(item => {
                              return <tr key={item.id}>
                                <td>
                                  <Link to={{
                                    pathname: `${url}/${item?.id}`,
                                    state: {
                                      item: item,
                                      itemName: products.find(product => Number(product.value) === item.productId)?.label,
                                      prevPath: url
                                    },
                                  }}
                                    style={{ color: "#66615B", width: "100%" }}>
                                    {item.customer?.name}
                                  </Link>
                                </td>
                                <td>
                                  {products.find(product => Number(product.value) === item.productId)?.label}
                                </td>
                                <td>{convertDate(item.createdAt)}</td>  
                                <td>{item.currentCharge.chargeAmount}</td>
                                <td>{convertDate(item.currentCharge.createdAt)}</td>
                                <td className="text-center" style={{ fontSize: '16px' }}>
                                  {
                                    item.status === 'canceled' ? <Badge color="canceled">Cancelada</Badge> :
                                    item.status === 'active' ? <Badge color="active">Ativa</Badge> :
                                    item.status === 'pastdue' ? <Badge color="pastdue">Atrasada</Badge> :
                                    item.status === 'expired' ? <Badge color="expired">Expirado</Badge> :
                                    item.status
                                  }
                                </td>
                                <td className="text-right">{
                                  item.item_price.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}</td>
                                <td className="text-right"
                                  style={{ fontSize: 20 + 'px' }}>
                                  <i className={getFaFaIcon(item.method)} id={'tooltip' + item.id} />{" "}
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={'tooltip' + item.id}
                                  >
                                    {item.method}
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                            })}
                        </tbody>
                      </Table>
                      {subscriptionsData?.length !== totalSubscription &&
                        <Row>
                          <div className="update ml-auto mr-auto">
                            <Button
                              className="mb-3 btn-round btn-zeeplo-orange"
                              disabled={isSalesLoading}
                              onClick={loadMore}
                            >
                              {isSalesLoading ? "Carregando..." : "Carregar Mais"}
                            </Button>
                          </div>
                        </Row>
                      }
                </CardBody>
              </Card>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}

export default SubscriptionSales;