import { combineReducers, createStore } from "redux";

import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from 'redux-firestore'; 

const reducer = combineReducers({
  // here we will be adding reducers
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

const INITIAL_STATE = {};

const store = createStore(reducer, INITIAL_STATE);

export default store;
