import React, { useEffect, useState, useCallback } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Button,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Form,
    FormGroup
} from "reactstrap";
import Collapsible from 'react-collapsible'
import { useParams } from "react-router";
import GoogleMapReact from "google-map-react";
import { mapStyle } from "../map-style";
import Order from '../services/OrdersByItem'
import Map from '../services/Map'
import "../styles/colapse.css"
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {
  isBrowser,
  isSafari
} from "react-device-detect";

function HeatMap() {
    const { itemId } = useParams();
    const [countries, setCountries] = useState([]);

    const [mapLocation, setMapLocation] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [initialDate, setInitialDate] = useState("");
    const [finalDate, setFinalDate] = useState("");

    const getVendorInfo = useCallback(async (initial_date, final_date) => {

        setIsLoading(true);
        try {

            const orderSingleton = new Order().getInstance()
            const response = await orderSingleton.getOrdersByItem(itemId, initial_date, final_date)

            if (response) {

                const mapLocations = Map.getMapLocations(response);
                setMapLocation(mapLocations);

                const items = response

                const result = _.chain(items).groupBy(item => item.country).value()
                const app = _.mapValues(result, items => {
                    const group = _.groupBy(items, item => item.province)
                    return Object.keys(group)
                        .map((item) => ({ province: item, data: Object.values(group[item]) }));
                })

                console.log("app", app)
                console.log(result)

                const getUserInfoFromItems = Object.keys(app)
                    .map((item) => ({ country: item, data: Object.values(app[item]) }));

                console.log(getUserInfoFromItems)

                setCountries(getUserInfoFromItems.sort((a, b) => { return b.data.reduce((acc, curr) => (acc + curr.data.length), 0) - a.data.reduce((acc, curr) => (acc + curr.data.length), 0) }))

            } else {

                setCountries([])
                setMapLocation([])
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }

    }, [itemId])

    const submitHandler = (e) => {
        e.preventDefault();

        if (initialDate && finalDate) {

            const data = {
                initial_date: moment(initialDate).format("DD-MM-YYYY"),
                final_date: moment(finalDate).format("DD-MM-YYYY")
            };

            console.log(data)

            getVendorInfo(data.initial_date, data.final_date);
        } else {
            getVendorInfo()
        }
    };

    const changeInitialDate = (e) => {
        setInitialDate(e.target.value);
    };

    const changeFinalDate = (e) => {
        setFinalDate(e.target.value);
    };

    useEffect(() => {
        getVendorInfo();
    }, [getVendorInfo, itemId]);

    if (isLoading) {
        return (<div className="content">
            <div>Loading...</div>
        </div>);
    }

    return (<div className="content">
        <Row>
            <Col md={{
                size: 8,
                offset: 2
            }}>
                <Form onSubmit={submitHandler}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg="4" md="4" sm="12" xs="12">
                                    <FormGroup>
                                        <label>Data de Inicio:</label>
                                        {isSafari && isBrowser ?
                                            (<DatePicker
                                                selected={initialDate}
                                                onChange={setInitialDate}
                                                customInput={<Input />}
                                            />) :
                                            (<Input
                                                name="initial_date"
                                                value={initialDate}
                                                onChange={changeInitialDate}
                                                type="date"
                                            />)
                                        }
                                    </FormGroup>
                                </Col>
                                <Col lg="4" md="4" sm="12" xs="12">
                                    <FormGroup>
                                        <label>Data de Fim:</label>
                                        {isSafari && isBrowser ?
                                            (<DatePicker
                                                selected={finalDate}
                                                onChange={setFinalDate}
                                                customInput={<Input />}
                                            />) :
                                            (<Input
                                                name="final_date"
                                                value={finalDate}
                                                onChange={changeFinalDate}
                                                type="date"
                                            />)
                                        }
                                    </FormGroup>
                                </Col>
                                <Col lg={{
                                    size: 3,
                                    offset: 1
                                }} md={{
                                    size: 3,
                                    offset: 1
                                }} sm="12" xs="12">
                                    <br />
                                    <Button className="mb-3 btn-round btn-zeeplo-orange" color="primary" type="submit">
                                        Consultar
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Form>
            </Col>
            <Col md={{
                size: 8,
                offset: 2
            }}>
                <Card>
                    <CardHeader>
                        <CardHeader>
                            <CardTitle tag="h5">Distribuição de vendas</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <center>
                                <div style={{
                                    height: "25rem",
                                    maxWidth: "25rem"
                                }}>
                                    <GoogleMapReact defaultZoom={1} heatmapLibrary={true} bootstrapURLKeys={{
                                        key: process.env.REACT_APP_GOOGLE_MAPS
                                    }} heatmap={{
                                        positions: mapLocation,
                                        options: {
                                            radius: 20,
                                            opacity: 0.6
                                        }
                                    }} defaultCenter={{
                                        lat: -15.5324041,
                                        lng: -60.9794413
                                    }} options={{
                                        styles: mapStyle,
                                        maxZoom: 6,
                                        minZoom: 1
                                    }} />
                                </div>
                            </center>
                        </CardBody>
                    </CardHeader>
                </Card>
            </Col>

            <Col lg={{
                size: 8,
                offset: 2
            }} md={{
                size: 10,
                offset: 1
            }} sm={{
                size: 10,
                offset: 1
            }}>


                <ListGroup>
                    <ListGroupItem disabled>País</ListGroupItem>
                    {countries.filter((item) => item.country !== "undefined").map((item) => (
                        <Collapsible trigger={<ListGroupItem>{item.country} <span className="badge badge-primary badge-pill float-right">{item.data.reduce((acc, curr) => (acc + curr.data.length), 0)}</span></ListGroupItem>}>
                            <ListGroupItem disabled  >Estado</ListGroupItem>
                            {item.data.sort((a, b) => { return b.data.length - a.data.length }).map((subitem) => (

                                <ListGroupItem className="m-pointer" key={subitem.province}>{subitem?.province !== "null" ? subitem?.province : "Não informado"} <span className="m-pointer badge badge-primary badge-pill float-right ">{subitem?.data?.length}</span></ListGroupItem>

                            ))}
                            <ListGroupItem>{""}</ListGroupItem>
                        </Collapsible>


                    ))}
                </ListGroup>
            </Col>
        </Row>
    </div>
    );
}

export default HeatMap;
