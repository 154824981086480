export default class Map {
  static getMapLocations(orders) {
    return orders
      .map((item) => {
        if (item) {
          const lat = item.latitude;
          const lng = item.longitude;

          return { lat, lng };
        }
        return null;
      })
      .filter((item) => item !== null && item.lat != null && item.lng != null);
  }
}