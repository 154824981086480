import { auth } from "./Firebase";
import api from "./api";
import moment from "moment";

class Sales {
  constructor() {
    this.token = null;
    this.filters = null;
    this.data = null;
    this.limit = 20;
  }

  getIdToken = async () => {
    const token = await auth.currentUser.getIdToken(true);
    this.token = token;
  };

  getOrdersByItem = async (filters) => {
    if (!this.data || JSON.stringify(filters) !== JSON.stringify(this.filters)){
      if (!this.token) {
        await this.getIdToken();
      }
      const response = await api.get("/ordersByItemsFilters", {
        params: { ...filters, token: this.token }
      });
      // eslint-disable-next-line no-unused-vars
      let startAfter;
      ({ startAfter, ...this.filters } = filters);
      if (!filters.startAfter) {
        this.data = response.data;
      }
      return response.data;
    }
    return this.data;
  };

  getFilters = () => {
    if (!this.filters) {
      const today = moment().format("DD-MM-YYYY");
      this.filters = {
        initial_date: today,
        final_date: today,
        limit: this.limit,
      };
    }
    return this.filters;
  }
}

export default class SalesSingleton {
  constructor() {
    if (!SalesSingleton.instance) {
      SalesSingleton.instance = new Sales();
    }
  }

  getInstance() {
    return SalesSingleton.instance;
  }
}
