import { auth } from "./Firebase";
import api from "./api"

class FinanceByItem {
  constructor() {
    this.token = null;
    this.initial_date = null;
    this.final_date = null;
    this.itemId = null;
    this.dashboard = [];
  }

  getIdToken = async () => {
    const token = await auth.currentUser.getIdToken(true);
    this.token = token
  }

  getFinanceByItem = async (itemId, initial_date, final_date) => {


    if ((this.initial_date !== initial_date || this.final_date !== final_date) || this.itemId !== itemId) {

      if (!this.token) {
        await this.getIdToken()
      }

      const response = await api.get("/financeByItem", {
        params: {
          id: itemId,
          initial_date,
          final_date,
          token: this.token,
        },
      });

      this.initial_date = initial_date;
      this.final_date = final_date;
      this.itemId = itemId;
      this.dashboard = response.data;

      return response.data;
    }
    return this.dashboard;
  }
}

export default class FinanceByItemSingleton {
  constructor() {
    if (!FinanceByItemSingleton.instance) {
      FinanceByItemSingleton.instance = new FinanceByItem();
    }
  }

  getInstance() {
    return FinanceByItemSingleton.instance;
  }
}