const translateOrderStatus = orderStatus => {
  let translated = '';
  switch (orderStatus) {
    case 'canceled':
      translated = 'Cancelado';
      break;
    case 'approved':
      translated = 'Pago';
      break;
    case 'expired':
      translated = 'Expirado';
      break;
    case 'abandoned':
      translated = 'Abandonado';
      break;
    case 'billet_printed':
      translated = 'Boleto impresso';
      break;
    case 'waiting_payment':
      translated = 'Aguardando pagamento';
      break;
    case 'refunded':
      translated = 'Reembolsado';
      break;
    case 'refund_requested':
      translated = 'Reembolso solicitado';
      break;
    case 'paid':
      translated = 'Pago';
      break;
    case 'pending':
      translated = 'Aguardando pagamento';
      break;
    case 'chargeback':
      translated = 'Chargeback';
      break;
    default:
      translated = ''
      break;
  }
  return translated;
}

const allOrderStatus = [
  //{ptBr: 'Abandonado', enUs: 'abandoned' },
  {ptBr: 'Pago', enUs: 'approved' },
  {ptBr: 'Aguardando pagamento', enUs: 'waiting_payment' },
  {ptBr: 'Boleto impresso', enUs: 'billet_printed' },
  {ptBr: 'Cancelado', enUs: 'canceled' },
  {ptBr: 'Expirado', enUs: 'expired' },
  {ptBr: 'Reembolsado', enUs: 'refunded' },
  {ptBr: 'Chargeback', enUs: 'chargeback' }
];

module.exports = {
  allOrderStatus,
  translateOrderStatus
};
