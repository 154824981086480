import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  CardHeader,
  Table,
  FormGroup,
  Input,
  Form,
  Button,
  Modal,
  FormText,
  Progress,
  Badge
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import FinanceSingleton from "../services/Finance";
import BankAccountsSingleton from "../services/BankAccounts";
import moment from "moment";
import { useFirebase } from "react-redux-firebase";
import clearSingletons from "util/logout";

function Finance() {
  const [isLoading, setIsLoading] = useState(true);
  const [financeData, setFinanceData] = useState({});
  const [requestedTransferInput, setRequestTransferInput] = useState('');
  const [isTransferRequested, setIsTransferRequested] = useState(false);
  const [balanceAvailable, setBalanceAvailable] = useState("");
  const [isTransferVisible, setIsTransferVisible] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [bankAccountToTransfer, setBankAccountToTransfer] = React.useState(null);
  const [transferValueState, setTransferValueState] = useState("");
  const [bankAccountState, setBankAccountState] = useState("");
  const [transferValueWarningMessage, setTransferValueWarningMessage] = useState("");
  const [banks, setBanks] = useState([]);
  const [modalClassic, setModalClassic] = useState(false);
  const [bankSelect, setBankSelect] = useState(null);
  const [bankState, setBankState] = useState("");
  const [accountType, setAccountType] = useState(null);
  const [accountTypeState, setAccountTypeState] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [bankBranchState, setBankBranchState] = useState("");
  const [account, setAccount] = useState("");
  const [accountState, setAccountState] = useState("");
  const [companyDocument, setCompanyDocument] = useState("");
  const [companyDocumentState, setCompanyDocumentState] = useState("");
  const [accountOwner, setAccountOwner] = useState("");
  const [accountOwnerState, setAccountOwnerState] = useState("");
  const [isRegisterAccountLoading, setIsRegisterAccountLoading] = useState(false);
  const [pix, setPix] = useState("");
  const iconsColor = 'text-zeeplo-icons';
  const firebase = useFirebase();
  const [nivelProgresso, setNivelProgresso] = useState("");

  const niveis = {
    explorador: { min: 0, max: 1000000, minText: "0", maxText: "1M", texto: "Nível Explorador", color: "explorador" },
    especialista: { min: 1000000, max: 2500000, minText: "1M", maxText: "2.5M", texto: "Nível Especialista", color: "especialista" },
    visionario: { min: 2500000, max: 5000000, minText: "2.5M", maxText: "5M", texto: "Nível Visionário", color: "visinario"},
    mestre: { min: 5000000, max: 10000000, minText: "5M", maxText: "10M", texto: "Nível Mestre", color: "mestre" },
    lenda: { min: 10000000, max: 15000000, minText: "10M", maxText: "15M", texto: "Nível Lenda", color: "lenda" },
    icone: { min: 15000000, max: 20000000, minText: "15M", maxText: "20M", texto: "Nível Ícone", color: "icone" }
  };

  useEffect(() => {
    function obterNivelEProgresso(financeData) {
      if(financeData) {
        const total = financeData?.unavailable_balance + financeData?.to_transfer
        const valor = total + financeData?.transfers?.reduce((acc, transfer) => acc + transfer.value, 0);
        if (valor) {
          for (const [nivel, { min, max, minText, maxText, texto, color }] of Object.entries(niveis)) {
            if (valor >= min && valor <= max) {
              const nivel = {
                min: minText,
                max: maxText,
                nivel: texto,
                color: color,
                progresso: ((valor - min) / (max - min)) * 100
              };
              setNivelProgresso(nivel)
              console.log(nivel);
              return  nivel;
            }
          }
          return null;
        }
      }
    }
    obterNivelEProgresso(financeData);
  }, [financeData]);

  const fetchBanks = useCallback(async () => {
    const brasilApi = axios.create({
      baseURL: "https://brasilapi.com.br/api",
      headers: { Accept: "application/json" },
    });
    const response = await brasilApi.get("/banks/v1");
    const filteredBanks = response.data
      .filter(bank => bank.code)
      .sort((a, b) => a.code - b.code)
      .map(bank => {
        return {
          value: `${bank.code} - ${bank.name}`,
          label: `${bank.code} - ${bank.name}`
        }
      });
    setBanks(filteredBanks);
  }, []);

  useEffect(() => {
    async function getFinanceInfo() {
      try {
        document.querySelector(".navbar-brand").innerText = "Financeiro";
        const financeSingleton = new FinanceSingleton().getInstance();
        const financeData = await financeSingleton.getFinanceData();
        setFinanceData(financeData);
        setBalanceAvailable(financeData.to_transfer?.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
    getFinanceInfo();
    setBankAccounts([
      {
        value: "",
        label: "Carregando...",
        isDisabled: true
      },
    ]);
  }, []);

  const currencyMask = value => {
    // only numbers from keyboard
    value = value.replace(/\D/g, '');
    // cents separator
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    // thousands separator
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    value = 'R$ '.concat(value);
    return value;
  };

  const removeCurrencyMask = value => {
    const valueWithoutMask = value.replace(/[^\d,]/g, '');
    const parsedValue = parseFloat(valueWithoutMask.replace(',', '.'));
    return parsedValue;
  }

  const handleTransferInput = event => {
    setTransferValueState('');
    const value = currencyMask(event.target.value);
    if (value === 'R$ ') {
      setBalanceAvailable('R$ 0,00');
      return '';
    }
    const balance = financeData?.to_transfer - removeCurrencyMask(value);
    setBalanceAvailable(balance.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    }));
    return value;
  };

  const toggleTransferVisibillty = useCallback(async () => {
    setIsTransferVisible(!isTransferVisible);
    if (!isTransferVisible) {
      try {
        const bankAccountsSingleton = new BankAccountsSingleton().getInstance();
        let response = await bankAccountsSingleton.getBankAccounts();
        response = response.map(bankAccount => {
          return {
            value: bankAccount,
            label: `${bankAccount.bank} - Ag: ${bankAccount.bank_branch} Conta: ${bankAccount.account_number} - ${bankAccount.owner}`,
          }
        });
        setBankAccounts(response);
        setBankAccountToTransfer(response[0]);
      } catch (error) {
        console.log(error);
      }
    }
  }, [isTransferVisible]);

  const requestTransfer = useCallback(async () => {
    let missingFields = false;
    if (!requestedTransferInput || requestedTransferInput === "") {
      setTransferValueState("has-danger");
      setTransferValueWarningMessage('Esse campo é obrigatório.');
      missingFields = true;
    } else if (removeCurrencyMask(requestedTransferInput) > (financeData?.to_transfer + financeData?.unavailable_balance + 0.01)) {
      setTransferValueState("has-danger");
      setTransferValueWarningMessage('O saque precisa ser inferior ao saldo disponível somado ao saldo pendente.');
      missingFields = true;
    } else if (removeCurrencyMask(requestedTransferInput) <= 0) {
      missingFields = true;
      setTransferValueState("has-danger");
      setTransferValueWarningMessage('O saque precisa ser de um valor positivo.');
    }
    if (!bankAccountToTransfer) {
      setBankAccountState("has-danger");
      missingFields = true;
    }
    if (missingFields) {
      return;
    }
    setIsTransferRequested(true);
    try {
      const financeSingleton = new FinanceSingleton().getInstance();
      await financeSingleton.requestTransfer(
        removeCurrencyMask(requestedTransferInput),
        bankAccountToTransfer.value
      );
      const transfers = {
        id: Math.floor(Math.random() * 1000),
        value: removeCurrencyMask(requestedTransferInput),
        created_at: moment().format('DD/MM/YYYY'),
        status: "Solicitado"
      }
      const newFinanceData = { ...financeData };
      newFinanceData.transfers.unshift(transfers);
      newFinanceData.to_transfer -= removeCurrencyMask(requestedTransferInput);
    
      setFinanceData(newFinanceData); 
      setRequestTransferInput('');
      setIsTransferVisible(false);
      setIsTransferRequested(false);
    } catch (error) {
      setIsTransferRequested(false);
      clearSingletons();
      localStorage.setItem('expiredToken', true);
      firebase.logout();
    }

  }, [requestedTransferInput, financeData.to_transfer, bankAccountToTransfer]);

  const onChangeBankAccountSelect = (value) => {
    setBankAccountToTransfer(value);
    setBankAccountState("");
  }

  const toggleModalClassic = useCallback(() => {
    setModalClassic(!modalClassic);
    if (banks.length === 0) {
      fetchBanks();
    }
  }, [modalClassic, banks, fetchBanks]);

  const submitHandler = useCallback(async () => {
    let missingFields = false;
    if (!bankSelect) {
      setBankState("has-danger");
      missingFields = true;
    }
    if (!accountType) {
      setAccountTypeState("has-danger");
      missingFields = true;
    }
    if (!bankBranch || bankBranch === "") {
      setBankBranchState("has-danger");
      missingFields = true;
    }
    if (!account || account === "") {
      setAccountState("has-danger");
      missingFields = true;
    }
    if (!companyDocument || companyDocument === "") {
      setCompanyDocumentState("has-danger");
      missingFields = true;
    }
    if (!accountOwner || accountOwner === "") {
      setAccountOwnerState("has-danger");
      missingFields = true;
    }
    if (missingFields) {
      return;
    }
    const newBankAccount = {
      bank: bankSelect.value,
      account_type: accountType.value,
      bank_branch: bankBranch,
      account_number: account,
      document: companyDocument,
      owner: accountOwner,
      pix: pix
    }
    console.log(newBankAccount);
    setIsRegisterAccountLoading(true);
    try {
      const bankAccountsSingleton = new BankAccountsSingleton().getInstance();
      await bankAccountsSingleton.createBankAccount(newBankAccount);
      bankAccounts.push({
        value: newBankAccount,
        label: `${newBankAccount.bank} - Ag: ${newBankAccount.bank_branch} Conta: ${newBankAccount.account_number} - ${newBankAccount.owner}`
      });
      setBankAccounts(bankAccounts);
      setBankAccountToTransfer(bankAccounts[bankAccounts.length - 1]);
    } catch (error) {
      alert("Erro ao cadastrar a conta bancária. Entre em contato com o suporte.");
    }
    setIsRegisterAccountLoading(false);
    toggleModalClassic();
  }, [bankSelect, accountType, bankBranch, account, companyDocument, accountOwner, toggleModalClassic, bankAccounts, pix]);

  const onChangeBankSelect = (value) => {
    setBankSelect(value);
    setBankState("");
  }

  const onChangeAccountTypeSelect = (value) => {
    setAccountType(value);
    setAccountTypeState("");
  }

  const onChangeBankBranch = (value) => {
    setBankBranch(value);
    setBankBranchState("");
  }

  const onChangeAccount = (value) => {
    setAccount(value);
    setAccountState("");
  }

  const onChangeCompanyDocument = (value) => {
    setCompanyDocument(value);
    setCompanyDocumentState("");
  }

  const onChangeAccountOwner = (value) => {
    setAccountOwner(value);
    setAccountOwnerState("");
  }

  if (isLoading) {
    return (
      <div className="content">
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <>
      <div className="content">
          <Row>
            <h5 className="pl-3">
              Receita total
            </h5>
          </Row>
        <Form>
             <Card>
             <CardBody>
                <Row>
                    <Col lg="4" md="4" sm="4" xs="4">
                        <div className="numbers pull-left"> {nivelProgresso?.min} </div>
                        {/* <div className="numbers pull-left"> {nivelProgresso?.min} <span className="badge">{nivelProgresso?.progresso}</span></div> */}
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4" className="pull-center">
                      <div className="text-center" style={{ fontSize: '16px' }} >
                        <Badge color={`${nivelProgresso?.color}`}>{nivelProgresso?.nivel}</Badge>
                      </div>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                        <div className="numbers pull-right"> {nivelProgresso?.max}</div>
                    </Col>
                </Row>
                <Row>
                <Col >
                <Progress max="100" value={nivelProgresso?.progresso} barClassName="progress-bar"  />
                  </Col>
                </Row> 
                </CardBody>
                <CardFooter>
                      <hr />
                      <div className="stats">{ moment().format('[Hoje às] HH:mm')}</div>
                 </CardFooter>  
              </Card>
          </Form>
        <Row>
          <Col lg="4" md="4" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className={`nc-icon nc-money-coins ${iconsColor}`} />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category">Saldo Total</p>
                      <CardTitle tag="p">
                        <span style={{ fontSize: 28 }}>
                          {Number(financeData?.unavailable_balance + financeData?.to_transfer).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </span>
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Row>
                  <Col sm="7">
                    <div className="stats">{moment().format('[Hoje às] HH:mm')}</div>
                  </Col>
                  <Col sm="5">
                    <div className="pull-right" style={{visibility: 'hidden' }} >
                      <Button
                        className="btn-round btn-icon btn-zeeplo-blue"
                        color="warning"
                        size="sm"
                      >
                        <i className="nc-icon nc-simple-add" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="4" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className={`nc-icon nc-money-coins ${iconsColor}`} />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category">Saldo Disponível</p>
                      <CardTitle tag="p">
                        <span style={{ fontSize: 28 }}>
                          {financeData?.to_transfer?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </span>
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Row>
                  <Col sm="7">
                    <div className="footer-title">Efetuar saque</div>
                  </Col>
                  <Col sm="5">
                    <div className="pull-right">
                      <Button
                        className="btn-round btn-icon btn-zeeplo-orange"
                        size="sm"
                        onClick={toggleTransferVisibillty}
                      >
                        {isTransferVisible ? <i className="nc-icon nc-minimal-up" /> : <i className="nc-icon nc-minimal-down" />}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="4" sm="12" xs="12">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="3" xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className={`nc-icon nc-calendar-60 ${iconsColor}`} />
                    </div>
                  </Col>
                  <Col md="9" xs="8">
                    <div className="numbers">
                      <p className="card-category">Saldo Pendente</p>
                      <CardTitle tag="p">
                        <span style={{ fontSize: 28 }}>
                          {financeData?.unavailable_balance?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </span>
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Row>
                  <Col sm="9">
                    <div className="footer-title">Antecipar saldo pendente</div>
                  </Col>
                  <Col sm="3">
                    <div className="pull-right" style={{visibility: 'hidden' }}>
                      <Button
                        className="btn-round btn-icon btn-zeeplo-orange"
                        size="sm"
                      >
                        <i className="nc-icon nc-simple-add" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          {isTransferVisible &&
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Qual valor deseja sacar?</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" lg="6" md="6" sm="12" xs="12">
                        <FormGroup className={`has-label ${transferValueState}`}>
                          <label>Valor do Saque</label>
                          <Input
                            value={requestedTransferInput}
                            onChange={e => setRequestTransferInput(handleTransferInput(e))}
                            placeholder="R$ 0,00"
                          />
                          {transferValueState === "has-danger" ? (
                            <label style={{ color: "#ef8157" }}>{transferValueWarningMessage}</label>
                          ) : null}
                          <FormText color="default" tag="span">
                            Saques realizados após às 15h horas seram efetuados no próximo dia útil.
                          </FormText>
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" lg="6" md="6" sm="12" xs="12">
                        <FormGroup>
                          <label>Saldo Restante</label>
                          <Input
                            disabled
                            value={balanceAvailable}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={bankAccountToTransfer}
                            onChange={(value) => onChangeBankAccountSelect(value)}
                            options={bankAccounts}
                            placeholder="Conta bancária"
                          />
                          {bankAccountState === "has-danger" ? (
                            <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          className="btn-link btn-round btn-zeeplo-purple-1"
                          onClick={toggleModalClassic}
                        >
                          Cadastrar nova conta bancária
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="mb-0 btn-round btn-zeeplo-orange"
                          color="primary"
                          onClick={requestTransfer}
                          disabled={isTransferRequested}
                        >
                          {isTransferRequested ? "Solicitando..." : "Confirmar Saque"}
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          }
          <h5 className="pl-3">
            Saques
          </h5>
          <Col md="12">
            <Card>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Data</th>
                      <th>Valor</th>
                      <th className="text-right">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {financeData?.transfers?.map(transfer => {
                      return <tr key={transfer.id}>
                        <td>{transfer.created_at}</td>
                        <td>{transfer.value.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}</td>
                        <td className="text-right">{transfer.status}</td>
                      </tr>
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="text-center" md="12">
            {/* Classic Modal */}
            <Modal isOpen={modalClassic} toggle={toggleModalClassic}>
              <div className="modal-header justify-content-center">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModalClassic}
                >
                  <i className="nc-icon nc-simple-remove" />
                </button>
                <h4 className="title title-up">Cadastrar Conta Bancária</h4>
              </div>
              <div className="modal-body">
                <Card className="card-plain">
                  <Row>
                    <Col className="text-dark">
                      <FormGroup className="has-danger">
                        <label>Banco *</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={bankSelect}
                          onChange={(value) => onChangeBankSelect(value)}
                          options={banks}
                          placeholder=""
                        />
                        {bankState === "has-danger" ? (
                          <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-dark">
                      <FormGroup>
                        <label>Tipo de conta *</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={accountType}
                          onChange={(value) => onChangeAccountTypeSelect(value)}
                          options={[
                            { value: "Conta corrente", label: "Conta corrente" },
                            { value: "Conta corrente cojunta", label: "Conta corrente cojunta" },
                            { value: "Conta poupança", label: "Conta poupança" },
                            { value: "Conta poupança cojunta", label: "Conta poupança cojunta" }
                          ]}
                          placeholder=""
                        />
                        {accountTypeState === "has-danger" ? (
                          <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-dark">
                      <FormGroup className={`has-label ${bankBranchState}`}>
                        <label>Agência *</label>
                        <Input
                          value={bankBranch}
                          type="text"
                          onChange={(e) => onChangeBankBranch(e.target.value)}
                        />
                        {bankBranchState === "has-danger" ? (
                          <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-dark">
                      <FormGroup className={`has-label ${accountState}`}>
                        <label>Conta *</label>
                        <Input
                          value={account}
                          type="text"
                          onChange={(e) => onChangeAccount(e.target.value)}
                        />
                        {accountState === "has-danger" ? (
                          <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-dark">
                      <FormGroup className={`has-label ${companyDocumentState}`}>
                        <label>CNPJ *</label>
                        <Input
                          value={companyDocument}
                          type="text"
                          onChange={(e) => onChangeCompanyDocument(e.target.value)}
                        />
                        {companyDocumentState === "has-danger" ? (
                          <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-dark">
                      <FormGroup className={`has-label ${accountOwnerState}`}>
                        <label>Razão social do favorecido *</label>
                        <Input
                          type="text"
                          value={accountOwner}
                          onChange={(e) => onChangeAccountOwner(e.target.value)}
                        />
                        {accountOwnerState === "has-danger" ? (
                          <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>PIX</label>
                        <Input
                          type="text"
                          value={pix}
                          onChange={(e) => setPix(e.target.value)}
                        />
                        <FormText color="default" tag="span">
                          PIX estão sujeito ao limite diário da Zeeplo. Em caso de limite insuficiente, será realizada uma transferência ao invés do PIX.
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div className="modal-footer">
                <div className="left-side">
                  <Button
                    className="btn-link"
                    color="default"
                    data-dismiss="modal"
                    type="button"
                    onClick={submitHandler}
                    disabled={isRegisterAccountLoading}
                  >
                    {isRegisterAccountLoading ? "Aguarde..." : "Cadastrar"}
                  </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                  <Button
                    className="btn-link"
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalClassic}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Finance;
