import React, { useEffect, useState } from "react";

import {
  Col,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { useParams } from "react-router";

import Order from "../services/OrdersByItem";
import Collapsible from "react-collapsible";
import "../styles/colapse.css";

function Code() {
  const { itemId } = useParams();
  const [codes, setCodes] = useState([]);
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [input, setInput] = useState("");

  const removeSpecialChar = (str) =>
    str.normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");

  useEffect(() => {
    async function getItemsInfo() {
      try {
        const orderSingleton = new Order().getInstance();
        const response = await orderSingleton.getOrdersByItem(itemId);
        const items = response;
        const codes = [];
        let new_item = true;

        items.forEach((product) => {
          if (product.discount_codes) {
            product.discount_codes.forEach((code) => {
              for (var i = 0; i < codes.length; i++) {
                if (code.code === codes[i].code) {
                  codes[i].amount += 1;
                  codes[i].buyer.push(product?.name || product.email);
                  new_item = false;
                  break;
                } else {
                  new_item = true;
                }
              }

              if (new_item) {
                codes.push({
                  code: code.code,
                  amount: 1,
                  buyer: [product?.name || product.email],
                });
                new_item = false;
              }
            });
          }
        });

        console.log(codes);

        setCodes(codes);
        setFilteredCodes(codes);

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    getItemsInfo();
  }, [itemId]);

  if (isLoading) {
    return (
      <div className="content">
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <>
      <div className="content">
        <Col
          lg={{ size: 8, offset: 1 }}
          md={{ size: 10, offset: 1 }}
          sm={{ size: 10, offset: 1 }}
        >
          <InputGroup>
            <Input
              onChange={(e) => {
                setInput(e.target.value);
              }}
              id="text"
              style={{ margin: "10px 0px" }}
            />
            <InputGroupAddon addonType="append">
              <Button
                className="btn-zeeplo-orange"
                onClick={() => {
                  setFilteredCodes(
                    codes.filter((item) =>
                      removeSpecialChar(item.code.toLowerCase()).includes(
                        removeSpecialChar(input.toLowerCase())
                      )
                    )
                  );
                }}
              >
                Pesquisar
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <ListGroup>
            {filteredCodes
              .sort((a, b) => {
                return b.amount - a.amount;
              })
              .map((item) => (
                <Collapsible
                  trigger={
                    <ListGroupItem key={item.code}>
                      {item.code}{" "}
                      <span className="badge badge-primary badge-pill float-right">
                        {item.amount}
                      </span>
                    </ListGroupItem>
                  }
                >
                  <ListGroupItem disabled>Compradores</ListGroupItem>
                  {item.buyer.sort().map((subitem, i) => (
                    <ListGroupItem className="m-pointer" key={i}>
                      {subitem}
                    </ListGroupItem>
                  ))}
                  <ListGroupItem>{""}</ListGroupItem>
                </Collapsible>
              ))}
          </ListGroup>
        </Col>
      </div>
    </>
  );
}

export default Code;
