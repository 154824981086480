import React, { useState, useCallback } from "react";
import { useLocation, useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import api from "services/api";
import { auth } from "services/Firebase";
import TelegramBotSingleton from "services/TelegramBot";

import {
  Badge,
  Col,
  FormGroup,
  Row,
  Card,
  CardBody,
  Form,
  Label,
  UncontrolledTooltip,
  Button,
  Modal,
  Input
} from "reactstrap";

function SubscriptionDetails() {
  const moment = require('moment');
  const [modalClassic, setModalClassic] = useState(false);
  const [isRefundLoading, setIsRefundLoading] = useState(false);
  const [refundReasonState, setRefundReasonState] = useState("");
  const [refundReason, setRefundReason] = useState("");
  const [refundRequested, setRefundRequested] = useState(false);

  const location = useLocation();
  const { item, itemName, prevPath } = location.state;

  const checkPaymentMethod = paymentMethod => {
    toggleModalClassic();
  };

  const toggleModalClassic = useCallback(() => {
    setModalClassic(!modalClassic);
  }, [modalClassic]);
  
  const getNamePayment = paymentMethod => {
    let name = '';
    switch (paymentMethod) {
      case 'credit_card':
        name = 'Cartão de Crédito';
        break;
      case 'billet':
        name = 'Boleto';
        break;
      case 'pix':
        name = 'Pix';
        break;
      case 'paypal':
        name = 'PayPal';
        break;
      default:
        name = 'Dinheiro';
        break;
    }
    return name;
  }
  const getFaFaIcon = paymentMethod => {
    let icon = '';
    switch (paymentMethod) {
      case 'credit_card':
        icon = 'fa fa-credit-card';
        break;
      case 'billet':
        icon = 'fa fa-barcode';
        break;
      case 'pix':
        icon = 'fa fa-qrcode';
        break;
      case 'paypal':
        icon = 'fab fa-paypal';
        break;
      default:
        icon = 'far fa-money-bill-alt';
        break;
    }
    return icon;
  }

  const onChangeReason = (value) => {
    setRefundReason(value);
    setRefundReasonState("");
  };

  function convertDate(obj) {
    if (obj && obj._seconds) {
      const timestamp = obj._seconds * 1000;
      const date = moment(timestamp);
      
      // Use a função format para formatar a data no formato desejado
      return date.format('DD/MM/YYYY');
    }
    return null;
  }

  const refundOrder = useCallback(async () => {
    if (!refundReason || refundReason === "") {
      setRefundReasonState("has-danger");
      return;
    }
    setIsRefundLoading(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      await api.put("/subscriptionCancel", {
        token,
        refund_reason: refundReason,
        subscriptions: item.id,
      });
      item.status = 'canceled';
      const telegramBotSingleton = new TelegramBotSingleton().getInstance();
      await telegramBotSingleton.requestRefund(
        `\n<b>Cancelamento Assinatura</b>\n` +
        `\n<b>Data da assinatura</b>\n${convertDate(item?.createdAt)}\n` +
`\n<b>Email do cliente</b>\n${item?.customer?.email}\n` +
        `\n<b>Valor</b>\n${item?.total_price.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}\n` +
        `\n<b>Motivo</b> \n${refundReason}\n` +
        `\n<a href="https://digitalmanager.guru/admin/subscriptions/${item.id}">Ver no Guru</a>`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setRefundRequested(true);
      toggleModalClassic();
      setIsRefundLoading(false);
    }
  },[refundReason, item]);


  return (
    <>
      <div className="content">
        <Col
          lg={{ size: 8, offset: 1 }}
          md={{ size: 10, offset: 1 }}
          sm={{ size: 10, offset: 1 }}
        >
          <Row>
            <h5 className="pl-3">
              Detalhes
            </h5>
          </Row>
          <Card>
            <CardBody>
              <Form className="form-horizontal">
                <Row>
                  <Label sm="2">ID da assinatura</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.id}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Status</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {
                          item.status === 'canceled' ? <Badge color="danger">Cancelada</Badge> :
                          item.status === 'active' ? <Badge color="success">Ativa</Badge> :
                          item.status === 'pastdue' ? <Badge color="warning">Atrasada</Badge> :
                          item.status === 'expired' ? <Badge color="#000" >Expirado</Badge> :
                          item.status
                        }
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Criada em </Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {convertDate(item?.createdAt)}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Valor</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.item_price ? item.item_price.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }) : item?.total_price.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Produto</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {itemName}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Forma de pagamento</Label>
                  <Col sm="4">
                    <FormGroup>
                      <p className="form-control-static">
                      {getNamePayment(item.method) + " "}
                      <i className={getFaFaIcon(item.method)} id={'tooltip' + item.id} />{" "}
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={'tooltip' + item.id}
                                  >
                                    {item.method}
                                  </UncontrolledTooltip>
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Row>
            {item.status === 'active' ? (
              <Col className="ml-auto text-right">
                <Button
                  className="btn-link"
                  color="danger"
                  onClick={() => checkPaymentMethod(item?.method)}
                >
                  Cancelar assinatura
                </Button>
              </Col>
            ) : null}
          </Row>
            <Row>
              <h5 className="pl-3">
                 Período Atual
              </h5>
            </Row>
            <Card>
              <CardBody>
                <Form className="form-horizontal">
                <Row>
                    <Label sm="2">Iniciado em</Label>
                    <Col sm="10">
                      <FormGroup>
                        <p className="form-control-static">
                          <td>{convertDate(item.createdAt)}</td>  
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Termina em</Label>
                    <Col sm="10">
                      <FormGroup>
                        <p className="form-control-static">
                        <td>{convertDate(item.currentCharge.endAt)}</td>  
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Próxima cobrança</Label>
                    <Col sm="10">
                      <FormGroup>
                        <p className="form-control-static">
                         <td>{convertDate(item.currentCharge.nextCharge)}</td>  
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                     <Label sm="2">Quantidade de cobrança</Label>
                    <Col sm="4">
                      <FormGroup>
                        <p className="form-control-static">
                          {item?.currentCharge.chargeAmount}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          <Row>
            <h5 className="pl-3">
              Cliente
            </h5>
          </Row>
          <Card>
            <CardBody>
              <Form className="form-horizontal">
                <Row>
                  <Label sm="2">Nome</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.customer.name}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Email</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.customer.email}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Celular</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.customer.phone}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">CPF</Label>
                  <Col sm="10">
                    <FormGroup>
                      <p className="form-control-static">
                        {item?.customer.cpf}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
         
        </Col>
        <Row>
          <Col className="text-center" md="12">
            {/* Classic Modal */}
            <Modal isOpen={modalClassic} toggle={toggleModalClassic}>
              <div className="modal-header justify-content-center">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModalClassic}
                >
                  <i className="nc-icon nc-simple-remove" />
                </button>
                <h4 className="title title-up">Cancelar Assinatura</h4>
              </div>
              <div className="modal-body">
                <Card className="card-plain">
                  <Col>
                    <FormGroup className={`has-label ${refundReasonState}`}>
                      <label>Motivo *</label>
                      <Input
                        className="textarea"
                        type="textarea"
                        cols="80"
                        rows="4"
                        value={refundReason}
                        onChange={(e) => onChangeReason(e.target.value)}
                      />
                      {refundReasonState === "has-danger" ? (
                        <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                      ) : null}
                    </FormGroup>
                    <div className="category form-category">
                      * Obrigatório
                    </div>
                  </Col>
                </Card>
              </div>
              <div className="modal-footer">
                <div className="left-side">
                  <Button
                    className="btn-link"
                    color="default"
                    data-dismiss="modal"
                    type="button"
                    onClick={refundOrder}
                    disable={isRefundLoading}
                  >
                    {isRefundLoading ? "Aguarde..." : "Confimar"}
                  </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                  <Button
                    className="btn-link"
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModalClassic}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SubscriptionDetails;
