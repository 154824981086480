/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Finance from "views/Finance.js"
import Sales from "views/Sales.js"
import Order from "views/Order.js"
import OrderRefund from "views/OrderRefund.js"
import ItemList from "views/ItemList.js";
import ItemDashboard from "views/ItemDashboard.js";
import Transactions from "views/Transactions";
import TransactionsItem from "views/TransactionsItem";
import Code from 'views/Code'
import CodeList from 'views/CodeList'
import HeatMap from 'views/HeatMap'
import HeatMapList from 'views/HeatMapList'
import TransactionPerItem from "views/transactionPerItem";
import SubscriptionOverview from "views/subscription/SubscriptionOverview";
import SubscriptionSales from "views/subscription/SubscriptionSales";
import SubscriptionDetails from "views/subscription/SubscriptionDetails";
import SettingCampaigns from "views/SettingCampaigns";
import SettingCampaignsDetails from "views/SettingCampaignsDetails";

var routes = [
  {
    path: "/dashboard",
    name: "Geral",
    icon: "nc-icon nc-chart-bar-32",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/map/:itemId",
    name: "Mapa",
    icon: "none",
    component: HeatMap,
    layout: "/admin",
  },
  {
    path: "/items/:itemId",
    name: "Produto",
    icon: "none",
    component: ItemDashboard,
    layout: "/admin",
  },
  {
    path: "/items",
    name: "Produtos",
    icon: "nc-icon nc-bullet-list-67",
    component: ItemList,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Assinaturas",
    icon: "nc-icon nc-refresh-69",
    state: "pagesCollapse",
    views: [
      {
        path: "/subscriptionOverview",
        name: "Visão Geral",
        mini: "VG",
        component: SubscriptionOverview,
        layout: "/admin"
      },
      {
        path: "/subscriptionSales",
        name: "Listagem",
        mini: "V",
        component: SubscriptionSales,
        layout: "/admin"
      },
      {
        path: "/subscriptionSales/:id",
        name: "Vendas",
        icon: "none",
        component: SubscriptionDetails,
        layout: "/admin",
      }
    ]
  },
  {
    path: "/sales/:id/refund",
    name: "Reembolsar Venda",
    icon: "none",
    component: OrderRefund,
    layout: "/admin",
  },
  {
    path: "/sales/:id",
    name: "Venda",
    icon: "none",
    component: Order,
    layout: "/admin",
  },
  {
    path: "/sales",
    name: "Vendas",
    icon: "nc-icon nc-tile-56",
    component: Sales,
    layout: "/admin",
  },
  {
    path: "/campanhas",
    name: "Campanhas",
    icon: "nc-icon nc-map-big",
    url: "https://lookerstudio.google.com/embed/u/0/reporting/f1d7f4b2-495f-4fe1-acfc-7ab176c4183d/page/p_5jv44qduad?params=",
    component: SettingCampaigns,
    layout: "/admin",
  },
  {
     path: "/campanhas/:id",
     name: "campanhas",
     icon: "none",
     component: SettingCampaignsDetails,
      layout: "/admin",
  },
  // {
  //   path: "/transactions/:itemId/:id",
  //   name: "Pedido",
  //   icon: "none",
  //   component: TransactionsItem,
  //   layout: "/admin",
  // },
  // {
  //   path: "/transactions/:itemId",
  //   name: "Pedido",
  //   icon: "none",
  //   component: Transactions,
  //   layout: "/admin",
  // },
  // {
  //   path: "/transactions",
  //   name: "Pedidos",
  //   icon: "nc-icon nc-tile-56",
  //   component: TransactionPerItem,
  //   layout: "/admin",
  // },
  {
    path: "/codes",
    name: "Cupons",
    icon: "nc-icon nc-bullet-list-67",
    component: CodeList,
    layout: "/admin",
  },
  {
    path: "/codes/:itemId",
    name: "Cupons",
    icon: "none",
    component: Code,
    layout: "/admin",
  },
  {
    path: "/finance",
    name: "Financeiro",
    icon: "nc-icon nc-credit-card",
    component: Finance,
    layout: "/admin",
  },
];
export default routes;
