import React from "react";
import { useLocation } from "react-router";
import { Col, Input, FormGroup, Row, Card, CardBody, Form } from "reactstrap";

import "../styles/input.css";

function TransactionItem() {
  // const { id } = useParams();
  const location = useLocation();
  const { item } = location.state;

  return (
    <>
      <div className="content">
        <Col
          lg={{ size: 8, offset: 1 }}
          md={{ size: 10, offset: 1 }}
          sm={{ size: 10, offset: 1 }}
        >
          <Card className="card-user input-change">
            <CardBody>
              <Form>
                <Row>
                  <Col className="text-dark" md="12">
                    <FormGroup>
                      <label>Número de pedido</label>
                      <Input disabled defaultValue={item.id} type="text" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="" md="12">
                    <FormGroup>
                      <label>Nome</label>
                      <Input
                        disabled
                        defaultValue={item?.name}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="" md="6">
                    <FormGroup>
                      <label htmlFor="exampleInputEmail1">Email</label>
                      <Input disabled defaultValue={item.email} type="email" />
                    </FormGroup>
                  </Col>
                  {/* <Col className="" md="6">
                    <FormGroup>
                      <label htmlFor="exampleInputEmail1">Telefone</label>
                      <Input
                        disabled
                        defaultValue={item.phone}
                        type="email"
                      />
                    </FormGroup>
                  </Col> */}
                  <Col md="6">
                    <FormGroup>
                      <label>Valor total</label>
                      <Input
                        defaultValue={item.total_price.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Estado</label>
                      <Input
                        defaultValue={item.province}
                        disabled
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label>Cidade</label>
                      <Input
                        defaultValue={item.city}
                        disabled
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>Endereço</label>
                      <Input
                        defaultValue={item.address1}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Complemento</label>
                      <Input
                        defaultValue={item.address2}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label>CEP</label>
                      <Input
                        defaultValue={item.zip}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                {/* {item.discount_codes && (
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <h5>Códigos de Desconto</h5>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                {item.discount_codes &&
                  item.discount_codes.map((discount) => (
                    <Row key={discount.code}>
                      <Col md="4">
                        <FormGroup>
                          <label>Valor</label>
                          <Input
                            defaultValue={discount.amount.toLocaleString(
                              "pt-BR",
                              {
                                style: "currency",
                                currency: "BRL",
                              }
                            )}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label>Código</label>
                          <Input
                            defaultValue={discount.code}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label>Tipo</label>
                          <Input
                            defaultValue={discount.type}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ))} */}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </div>
    </>
  );
}

export default TransactionItem;
