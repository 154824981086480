import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
    Col,
    Card,
    CardBody,
    Row,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import activeCampaing from '../assets/img/AC_Glyph_Blue.svg';
import googleAds from '../assets/img/google-ads-svgrepo-com.svg';
import facebook from '../assets/img/facebook-2020-2-1.svg';
import convertkit from '../assets/img/convertkit.svg';
import { auth } from "services/Firebase";
import api from "services/api";

function SettingCampaignsDetails() {

    const location = useLocation();
    const [passwordShown, setPasswordShown] = useState(false);
    const [config, setConfig] = useState({ name: '', icon: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [inputValeu, setInputValeu] = useState({ key: '', account: '' });

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    useEffect(() => {
        const fetchApiMarketingByVendor = async () => {
            if (config && config.name !== '') {

                try {
                    const token = await auth.currentUser.getIdToken(true);
                    const response = await api.get("/apiMarketingByVendor", {
                        params: {
                            token: token,
                        },
                    });
                    if (response.data && response.data.list.length > 0) {
                        const configSelect = response.data.list.find(item => item.name === config.id);
                        if (configSelect) {
                            setInputValeu({ key: configSelect.key, account: configSelect.account });
                        }
                    }
                } catch (error) {

                }
            }

        }
        fetchApiMarketingByVendor();
    }, [config]);

    useEffect(() => {
        const pathname = location.pathname.split("/")[3];
        switch (pathname) {
            case 'activeCampaing':
                setConfig({ id: "activeCampaing", name: 'activeCampaing', icon: activeCampaing, field: 'API key', field2: 'Nome da conta' });
                break;
            case 'googleads':
                setConfig({ id: "googleads", name: 'Google Ads', icon: googleAds, field: 'id da conta de anúncios' });
                break;
            case 'facebookmarketing':
                setConfig({ id: "facebookmarketing", name: 'Facebook Marketing', icon: facebook, field: '', field2: '' });
                break;
            case 'convertkit':
                setConfig({ id: "convertkit", name: 'ConvertKit', icon: convertkit, field: 'API key', field2: '' });
                break;
            default:
                setConfig({ id: "", name: '', icon: '' });
        }


    }, [location]);

    const sendConfig = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const token = await auth.currentUser.getIdToken(true);
            const body = {
                name: config.id,
                key: inputValeu.key,
                account: inputValeu.account
            }
          
            await api.post("/apiMarketingCreate", body, {
                params: {
                  token: token
                },
              });
    
            alert('Configuração enviada com sucesso');

        } catch (error) {
            console.error('Error sending config:', error);
            alert('Erro ao enviar configuração');
        } 

        setIsLoading(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValeu(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <div className="content">
                <Row>
                    <h5 className="pl-3">
                        Adicionar Nova Conexão
                    </h5>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="12" xs="12">
                                        <Row style={{ alignItems: 'center' }}>
                                            <Col lg="9" md="9" sm="12" xs="12">
                                            </Col>
                                            <Col lg="1" md="1" sm="12" xs="12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <img src={config.icon} alt="" style={{ width: '56px', height: '56px' }} />
                                            </Col>
                                            <Col lg="2" md="2" sm="12" xs="12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <h6 style={{ margin: 0 }}>{config.name}</h6>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" xs="12">
                                        <h4> {config.field}</h4>
                                    </Col>
                                    <Col md="12" xs="12">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                                    <FontAwesomeIcon icon={passwordShown ? faEye : faEyeSlash} />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                name="key"
                                                value={inputValeu.key}
                                                placeholder={config.field}
                                                onChange={handleInputChange}
                                                type={passwordShown ? "text" : "password"}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {config.field2 && (
                                    <Row>
                                        <Col md="12" xs="12">
                                            <h4> {config.field2}</h4>
                                        </Col>
                                        <Col md="12" xs="12">
                                            <Input
                                                name="account"
                                                value={inputValeu.account}
                                                placeholder={config.field2}
                                                type={"text"}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                )

                                }
                                {
                                    config.name === 'Google Ads' && (
                                        <Row>
                                            <Col md="12" xs="12">
                                                <br></br>
                                                <Button
                                                    className="btn-link btn-round btn-zeeplo-purple-1"
                                                    href="https://docs.google.com/document/d/1qZWDAjliFps3L9PTnNHgiuUC_Ag3yxod3I9s4xqNRjg/edit"

                                                >
                                                    Ver Tutorial
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                }
                                <br></br>
                                <br></br>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <br />
                        <Button
                            className="mb-0 btn-round btn-zeeplo-orange btn-zeeplo-orange-subscription"
                            onClick={sendConfig}
                            disabled={isLoading}
                        >
                            {isLoading ? "Loading..." : "Enviar"}
                        </Button>
                    </Col>
                </Row>

            </div>

        </>
    );
}

export default SettingCampaignsDetails;