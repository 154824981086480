/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import firebase from "./services/Firebase";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminLayout from "layouts/Admin.js";
import Login from "views/login/Login";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import {
  UserIsAuthenticated,
  UserIsNotAuthenticated,
} from "./components/PrivateRoute";

const hist = createBrowserHistory();

// react-redux-firebase config
const rrfConfig = {
  userProfile: false,
  // useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  // createFirestoreInstance, // <- needed if using firestore
};

ReactDOM.render(
  <Router history={hist}>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Switch>
          <Redirect exact from="/" to="/admin/dashboard" />
          <Route path="/admin" component={UserIsAuthenticated(AdminLayout)} />
          <Route path="/login" component={UserIsNotAuthenticated(Login)} />
        </Switch>
      </ReactReduxFirebaseProvider>
    </Provider>
  </Router>,
  document.getElementById("root")
);
