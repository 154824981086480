import { auth } from "./Firebase";
import api from "./api"

class FinanceByVendor {
  constructor() {
    this.token = null;
    this.initial_date = null;
    this.final_date = null;
    this.dashboard = []
  }

  getIdToken = async () => {
    const token = await auth.currentUser.getIdToken(true);
    this.token = token
  }

  getFinanceByVendor = async (initial_date, final_date) => {

    if (this.initial_date !== initial_date || this.final_date !== final_date) {

      if (!this.token) {
        await this.getIdToken()
      }

      const response = await api.get("/financeByVendor", {
        params: {
          initial_date,
          final_date,
          token: this.token,
        },
      });

      this.initial_date = initial_date;
      this.final_date = final_date;
      this.dashboard = response.data;
      
      return response.data;
    }
    return this.dashboard;
  }

}

export default class FinanceByVendorSingleton {
  constructor() {
    if (!FinanceByVendorSingleton.instance) {
      FinanceByVendorSingleton.instance = new FinanceByVendor();
    }
  }

  getInstance() {
    return FinanceByVendorSingleton.instance;
  }
}