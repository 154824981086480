import React, { useEffect, useState, useCallback } from "react";
import { useRouteMatch } from "react-router";
import {
  Badge,
  Col,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  CardHeader,
  Table,
  FormGroup,
  Input,
  Form,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isBrowser, isSafari } from "react-device-detect";
import { Link } from "react-router-dom";
import SalesSingleton from "../services/Sales";
import Items from "../services/Items"
import { translateOrderStatus, allOrderStatus } from "variables/orderStatusPtBr";
import fileDownload from "js-file-download";
import { auth } from "services/Firebase";
import api from "services/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Sales() {
  const NUMBER_OF_ORDERS = 20;
  const { url } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerCPF, setCustomerCPF] = useState('');
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [multipleSelect, setMultipleSelect] = useState([]);
  const [multipleSelectStatus, setMultipleSelectStatus] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsState, setProductsState] = useState("");
  const [countOrders, setCountOrders] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [isSalesLoading, setIsSalesLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [status, setStatus] = useState([]);
  const [salesSingleton] = useState(new SalesSingleton().getInstance());

  const configureFilters = (filtersToConfig, productList) => {
    const { initial_date, final_date } = filtersToConfig;
    if (isSafari && isBrowser) {
      setInitialDate(new Date(moment(initial_date, 'DD-MM-YYYY')));
      setFinalDate(new Date(moment(final_date, 'DD-MM-YYYY')));
    } else {
      setInitialDate(moment(initial_date, 'DD-MM-YYYY').format("YYYY-MM-DD"));
      setFinalDate(moment(final_date, 'DD-MM-YYYY').format("YYYY-MM-DD"));
    }
    if (filtersToConfig.itemIds) {
      setMultipleSelect(filtersToConfig.itemIds.map(itemId => {
        return {
          value: itemId,
          label: productList.find(product => product.value === itemId).label
        }
      }));
      setIsFilterApplied(true);
    }
    if (filtersToConfig.status) {
      setMultipleSelectStatus(filtersToConfig.status.map(orderStatus => {
        return {
          value: orderStatus,
          label: allOrderStatus.find(oStatus => oStatus.enUs === orderStatus).ptBr
        }
      }));
      setIsFilterApplied(true);
    }
    if (filtersToConfig.name) {
      setCustomerName(filtersToConfig.name);
      setIsFilterApplied(true);
    }
    if (filtersToConfig.email) {
      setCustomerEmail(filtersToConfig.email);
      setIsFilterApplied(true);
    }
    if (filtersToConfig.cpf) {
      setCustomerCPF(filtersToConfig.cpf);
      setIsFilterApplied(true);
    }
  };

  const getSalesInfo = useCallback(async filters => {
    try {
      setOrders([]);
      setIsSalesLoading(true);
      const response = await salesSingleton.getOrdersByItem(filters);
      let data = localStorage.getItem('refundedOrder');
      if (data !== null) {
        data = JSON.parse(data);
        response.orders.forEach(order => {
          const refundedOrder = data[order.id];
          if (refundedOrder !== undefined && order.status !== 'refunded') {
            order.refundRequested = true;
          }
        });
      }
      setOrders(response.orders);
      setTotalOrders(response.count);
      setCountOrders(countOrders + NUMBER_OF_ORDERS);
      setIsLoading(false);
      setIsSalesLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsSalesLoading(false);
    }
  }, [countOrders, salesSingleton]);

  async function getItemsInfo() {
    try {
      const itemsSingleton = new Items().getInstance();
      let itemList = await itemsSingleton.getItems();
      itemList = itemList
        .map(item => {
          return {
            value: item.id,
            label: item.title
          }
        });
      itemList.unshift({
        value: "",
        label: "Selecione o(s) produto(s)",
        isDisabled: true
      });
      setProducts(itemList);
      const initialFilters = { ...salesSingleton.getFilters() };
      setFilters(initialFilters);
      getSalesInfo(initialFilters);
      configureFilters(initialFilters, itemList);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    document.querySelector(".navbar-brand").innerText = "Vendas";
    setProducts([
      {
        value: "",
        label: "Carregando...",
        isDisabled: true
      },
    ]);
    const orderStatus = allOrderStatus.map(orderStatus => {
      return {
        value: orderStatus.enUs,
        label: orderStatus.ptBr
      }
    });
    orderStatus.unshift({
      value: "",
      label: "Selecione o(s) status",
      isDisabled: true
    });
    setStatus(orderStatus);
    getItemsInfo();
  }, []);

  const loadMore = useCallback(async () => {
    try {
      setIsSalesLoading(true);
      filters.startAfter = orders[orders.length - 1].formated_created_at;
      const response = await salesSingleton.getOrdersByItem(filters);
      setOrders([...orders, ...response.orders]);
      setIsSalesLoading(false);
    } catch (error) {
      setIsSalesLoading(false);
    }
  }, [filters, orders, salesSingleton]);

  const downloadCSV = useCallback(async () => {
    setIsCsvLoading(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      const responseVendor = await api.get("/vendor", { params: { token } });
      const response = await api.get("/csvByItems", {
        params: {
          token,
          itemIds: filters.itemIds,
          initial_date: filters.initial_date,
          final_date: filters.final_date,
          name: filters.name,
          email: filters.email,
          cpf: filters.cpf,
          limit: filters.limit,
          status: filters.status,
        },
      });
      const vendor = responseVendor.data.vendor; //.replace(" ", "_");
      fileDownload(
        response.data,
        `Zeeplo_${vendor}_${moment().format("DD/MM/YYYY_TH:m")}.csv`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsCsvLoading(false);
    }
  }, [filters]);

  const submitHandler = (e) => {
    e.preventDefault();
    const newFilters = {}
    if (multipleSelect) {
      if (multipleSelect.length > 10) {
        setProductsState("has-danger");
        return;
      }
      if (multipleSelect.length > 0) {
        newFilters.itemIds = multipleSelect?.map(item => item.value);
      }
    }
    if (customerName) {
      newFilters.name = customerName;
    }
    if (customerEmail) {
      newFilters.email = customerEmail;
    }
    if (customerCPF) {
      newFilters.cpf = customerCPF;
    }
    if (initialDate) {
      newFilters.initial_date = moment(initialDate).format("DD-MM-YYYY");
    }
    if (finalDate) {
      newFilters.final_date = moment(finalDate).format("DD-MM-YYYY");
    }
    if (multipleSelectStatus && multipleSelectStatus.length > 0) {
      newFilters.status = multipleSelectStatus?.map(item => item.value);
    }
    newFilters.limit = NUMBER_OF_ORDERS;
    setTotalOrders(0);
    setIsFilterApplied(true);
    setFilters(newFilters);
    getSalesInfo(newFilters);
    localStorage.removeItem("refundedOrder");
  };

  const changeInitialDate = (e) => {
    setInitialDate(e.target.value);
  };

  const changeFinalDate = (e) => {
    setFinalDate(e.target.value);
  };

  const changeProducts = (e) => {
    setMultipleSelect(e);
    setProductsState("");
  };

  const collapsesToggle = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const clearFilters = () => {
    setCustomerName('');
    setCustomerEmail('');
    setCustomerCPF('');
    setIsFilterApplied(false);
    setMultipleSelect([]);
    setMultipleSelectStatus([]);
    const today = moment().format("DD-MM-YYYY");
    const initialFilters = {
      initial_date: today,
      final_date: today,
      limit: NUMBER_OF_ORDERS
    };
    configureFilters(initialFilters);
    setFilters(initialFilters);
  }

  const getOrderStatusLabel = status => {
    let color = '';
    switch (status) {
      case 'canceled':
        color = 'canceled';
        break;
      case 'approved':
        color = 'active';
        break;
      case 'expired':
        color = 'expired';
        break;
      case 'abandoned':
        color = 'abandoned';
        break;
      case 'billet_printed':
        color = 'billetprinted';
        break;
      case 'waiting_payment':
        color = 'waitingpayment';
        break;
      case 'refunded':
        color = 'inactive';
        break;
      case 'paid':
        color = 'active';
        break;
      case 'pending':
        color = 'pending';
        break;
      case 'chargeback':
        color = 'chargeback';
        break;
      default:
        color = 'warning'
        break;
    }
    return color;
  }

  const getFaFaIcon = paymentMethod => {
    let icon = '';
    switch (paymentMethod) {
      case 'cartão de crédito':
        icon = 'fa fa-credit-card';
        break;
      case 'boleto':
        icon = 'fa fa-barcode';
        break;
      case 'pix':
        icon = 'fa fa-qrcode';
        break;
      case 'paypal':
        icon = 'fab fa-paypal';
        break;
      default:
        icon = 'far fa-money-bill-alt';
        break;
    }
    return icon;
  }

  if (isLoading) {
    return (
      <div className="content">
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <>
      <div className="content">
        <Row>
          <h5 className="pl-3">
            Vendas
          </h5>
          <Col className="ml-auto text-right" >
            <Button
              className="btn-round"
              color="z-purple-1"
              onClick={downloadCSV}
              disabled={isCsvLoading}
              outline
            >
              {isCsvLoading ? "Carregando..." : <><i class="fa fa-file-export"></i> Exportar</>}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <div
                  aria-multiselectable={true}
                  className="card-collapse"
                  id="accordion"
                  role="tablist"
                >
                  <Card className="card-plain">
                    <CardHeader role="tab">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        aria-expanded={isFilterVisible}
                        href="#"
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => collapsesToggle()}
                      >
                        Filtros{" "}
                        <i className="nc-icon nc-minimal-down" />
                      </a>
                    </CardHeader>
                    <Collapse
                      role="tabpanel"
                      isOpen={isFilterVisible}
                    >
                      <CardBody>
                        <div
                          aria-multiselectable={true}
                          className="card-collapse"
                          id="accordion"
                          role="tablist"
                        >
                          <Form onSubmit={submitHandler}>
                            <Row>
                              <Col lg="3" md="3" sm="12" xs="12">
                                <FormGroup>
                                  <CardTitle>Nome do Cliente</CardTitle>
                                  <Input
                                    value={customerName}
                                    onChange={e => setCustomerName(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3" md="3" sm="12" xs="12">
                                <FormGroup>
                                  <CardTitle>Email do Cliente</CardTitle>
                                  <Input
                                    value={customerEmail}
                                    onChange={e => setCustomerEmail(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3" md="3" sm="12" xs="12">
                                <FormGroup>
                                  <CardTitle>CPF do cliente</CardTitle>
                                  <Input
                                    value={customerCPF}
                                    onChange={e => setCustomerCPF(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3" md="3" sm="12" xs="12">
                                <CardTitle>Produto</CardTitle>
                                <Select
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  placeholder="Todos os produtos"
                                  name="multipleSelect"
                                  closeMenuOnSelect={false}
                                  isMulti
                                  value={multipleSelect}
                                  onChange={(value) => changeProducts(value)}
                                  options={products}
                                />
                                {productsState === "has-danger" ? (
                                  <label style={{ color: "#ef8157" }}>Só é permitido selecionar 10 produtos</label>
                                ) : null}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="3" md="3" sm="12" xs="12">
                                <FormGroup>
                                  <CardTitle>Data de Início</CardTitle>
                                  {isSafari && isBrowser ? (
                                    <DatePicker
                                      selected={initialDate}
                                      onChange={setInitialDate}
                                      customInput={<Input />}
                                    />
                                  ) : (
                                    <Input
                                      name="initial_date"
                                      value={initialDate}
                                      onChange={changeInitialDate}
                                      type="date"
                                    />
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="3" md="3" sm="12" xs="12">
                                <FormGroup>
                                  <CardTitle>Data de Fim</CardTitle>
                                  {isSafari && isBrowser ? (
                                    <DatePicker
                                      selected={finalDate}
                                      onChange={setFinalDate}
                                      customInput={<Input />}
                                    />
                                  ) : (
                                    <Input
                                      name="final_date"
                                      value={finalDate}
                                      onChange={changeFinalDate}
                                      type="date"
                                    />
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="3" md="3" sm="12" xs="12">
                                <CardTitle>Status</CardTitle>
                                <Select
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  placeholder="Todos status"
                                  name="multipleSelect"
                                  closeMenuOnSelect={false}
                                  isMulti
                                  value={multipleSelectStatus}
                                  onChange={(value) => setMultipleSelectStatus(value)}
                                  options={status}
                                />
                              </Col>
                              <Col lg="3" md="3" sm="12" xs="12">
                                <br />
                                <Button
                                  className="mb-0 btn-round btn-zeeplo-orange"
                                  type="submit"
                                  disabled={isSalesLoading}
                                >
                                  {isSalesLoading ? "Loading..." : "Consultar"}
                                </Button>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Button
                                  className="btn-link btn-round btn-zeeplo-purple-1"
                                  disabled={!isFilterApplied}
                                  onClick={clearFilters}
                                >
                                  Limpar filtros
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                          <hr style={{ marginTop: 50 }}></hr>
                        </div>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card className="card-plain">
                    <CardBody>
                      <label className="card-text">{orders.length} de {totalOrders}</label>
                      <Table className="table-hover" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Cliente</th>
                            <th>Produto</th>
                            <th >Data</th>
                            <th className="text-center">Status</th>
                            <th className="text-right">Ganho</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.length === 0 ? (isSalesLoading ? <tr><td>Carregando...</td></tr> : <tr><td>Nenhuma venda encontrada</td></tr>) :
                            orders?.map(order => {
                              return <tr key={order.id}>
                                <td>
                                  <Link to={{
                                    pathname: `${url}/${order.id}`,
                                    state: {
                                      item: order,
                                      itemName: products.find(product => Number(product.value) === order.itemId)?.label,
                                      prevPath: url
                                    },
                                  }}
                                    style={{ color: "#66615B", width: "100%" }}>
                                    {order.name ? order.name : order.first_name + ' ' + order.last_name}
                                  </Link>
                                   {" "}<a href={`https://wa.me/${order.phone}`} target="_blank"><FontAwesomeIcon icon={faWhatsapp} size="lg"/></a>
                                </td>
                                <td>
                                  <Link to={{
                                    pathname: `${url}/${order.id}`,
                                    state: {
                                      item: order,
                                      itemName: products.find(product => Number(product.value) === order.itemId)?.label,
                                      prevPath: url
                                    },
                                  }}
                                    style={{ color: "#66615B", width: "100%" }}>
                                    {products.find(product => Number(product.value) === order.itemId)?.label}
                                  </Link>
                                </td>
                                <td>{order.formated_created_at}</td>
                                <td className="text-center" style={{ fontSize: 16 + 'px' }}>
                                  {order.status ? <Badge color={order.refundRequested ? getOrderStatusLabel('') : getOrderStatusLabel(order.status)} pill>
                                    {order.refundRequested ? 'Reembolso solicitado' : translateOrderStatus(order.status)}
                                  </Badge> :
                                    <Badge color={order.refundRequested ? getOrderStatusLabel('') : getOrderStatusLabel(order.financial_status)} pill>
                                      {order.refundRequested ? 'Reembolso solicitado' : translateOrderStatus(order.financial_status)}
                                    </Badge>}

                                </td>
                                <td className="text-right">{order.item_price ?
                                  order.comission?.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }) :
                                  order.total_price.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}</td>
                                <td className="text-right"
                                  style={{ fontSize: 20 + 'px' }}>
                                  <i className={getFaFaIcon(order.method)} id={'tooltip' + order.id} />{" "}
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={'tooltip' + order.id}
                                  >
                                    {order.method}
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                            })}
                        </tbody>
                      </Table>
                      {orders.length !== totalOrders &&
                        <Row>
                          <div className="update ml-auto mr-auto">
                            <Button
                              className="mb-3 btn-round btn-zeeplo-orange"
                              disabled={isSalesLoading}
                              onClick={loadMore}
                            >
                              {isSalesLoading ? "Carregando..." : "Carregar Mais"}
                            </Button>
                          </div>
                        </Row>
                      }
                    </CardBody>
                  </Card>
                </div>
              </CardBody>
            </Card>
          </Col>

        </Row>
      </div>
    </>
  );
}

export default Sales;
