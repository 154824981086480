import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  FormGroup,
  Row,
  Card,
  CardBody,
  CardFooter,
  Form
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import TelegramBotSingleton from "services/TelegramBot";
import { auth } from "services/Firebase";
import api from "services/api";

import "../styles/input.css";
import "../styles/card.css";

function OrderRefund() {
  const location = useLocation();
  const history = useHistory();
  const { item, prevPath } = location.state;
  const [banks, setBanks] = useState([]);
  const [bankSelect, setBankSelect] = useState(null);
  const [bankState, setBankState] = useState("");
  const [accountType, setAccountType] = useState(null);
  const [accountTypeState, setAccountTypeState] = useState("");
  const [agency, setAgency] = useState("");
  const [agencyState, setAgencyState] = useState("");
  const [account, setAccount] = useState("");
  const [accountState, setAccountState] = useState("");
  const [customerName, setCustomerName] = useState(item.name);
  const [customerNameState, setCustomerNameState] = useState("");
  const [refundReason, setRefundReason] = useState("");
  const [refundReasonState, setRefundReasonState] = useState("");
  const [isRefundLoading, setIsRefundLoading] = useState(false);

  const fetchBanks = useCallback(async () => {
    const brasilApi = axios.create({
      baseURL: "https://brasilapi.com.br/api",
      headers: { Accept: "application/json" },
    });
    const response = await brasilApi.get("/banks/v1");
    const filteredBanks = response.data
      .filter(bank => bank.code)
      .sort((a, b) => a.code - b.code)
      .map(bank => {
        return {
          value: `${bank.code} - ${bank.name}`,
          label: `${bank.code} - ${bank.name}`
        }
      });
    setBanks(filteredBanks);
  }, []);

  useEffect(() => {
    document.querySelector(".navbar-brand").innerText = "Dados para reembolso";
    fetchBanks()
  }, [fetchBanks]);

  const submitHandler = useCallback(async () => {
    
    let missingFields = false;
    if (!bankSelect) {
      setBankState("has-danger");
      missingFields = true;
    }
    if (!accountType) {
      setAccountTypeState("has-danger");
      missingFields = true;
    }
    if (!agency || agency === "") {
      setAgencyState("has-danger");
      missingFields = true;
    }
    if (!account || account === "") {
      setAccountState("has-danger");
      missingFields = true;
    }
    if (!customerName || customerName === "") {
      setCustomerNameState("has-danger");
      missingFields = true;
    }
    if (!refundReason || refundReason === "") {
      setRefundReasonState("has-danger");
      missingFields = true;
    }
    if (!missingFields) {
      setIsRefundLoading(true);
      const refundData =  `Data da venda: ${item.formated_created_at}` +
      `Email do cliente: ${item.email}` +
      `Valor: ${item?.total_price.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}` +
      `Motivo: ${refundReason}` +
      `Dados bancários: ${bankSelect.label} - ${accountType.label}` +
      `Ag: ${agency} Conta: ${account}` +
      `Favorecido: ${customerName}` +
      `CPF/CNPJ: ${item.cpf}` +
      `Guru: https://digitalmanager.guru/admin/transactions/${item.gateway}/${item.id}`

      try {
        const token = await auth.currentUser.getIdToken(true);
        await api.put("/requestRefund", {
          token,
          order_item_id: item.id,
          refund_reason: JSON.stringify(refundData)
        });
        const telegramBotSingleton = new TelegramBotSingleton().getInstance();
        await telegramBotSingleton.requestRefund(
          `\n<b>Data da venda</b>\n${item.formated_created_at}\n` +
          `\n<b>Email do cliente</b>\n${item.email}\n` +
          `\n<b>Valor</b>\n${item?.total_price.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}\n` +
          `\n<b>Motivo</b> \n${refundReason}\n` +
          `\n<b>Dados bancários</b>\n${bankSelect.label} - ${accountType.label}` +
          `\n<b>Ag</b>: ${agency} <b>Conta</b>: ${account}` +
          `\n<b>Favorecido</b>: ${customerName}` +
          `\n<b>CPF/CNPJ</b>: ${item.cpf}\n` +
          `\n<a href="https://digitalmanager.guru/admin/transactions/${item.gateway}/${item.id}">Ver no Guru</a>`
        );
        // setRefundRequested(true);
      } catch (error) {
        console.log(error);
      } finally {
        // store the order id to update status to refunded requested on sales screen
        let data = localStorage.getItem('refundedOrder');
        if (data) {
          data = JSON.parse(data);
        } else {
          data = {};
        }
        data[item.id] = true;
        localStorage.setItem('refundedOrder', JSON.stringify(data));
        history.push({ pathname: prevPath });
        setIsRefundLoading(false);
      }
    }
  },[account, accountType, agency, bankSelect, customerName, history, item.cpf, item.email, item.formated_created_at, item.gateway, item.id, item.total_price, prevPath, refundReason]);

  const onChangeBankSelect = (value) => {
    setBankSelect(value);
    setBankState("");
  }

  const onChangeAccountTypeSelect = (value) => {
    setAccountType(value);
    setAccountTypeState("");
  }

  const onChangeAgency = (value) => {
    setAgency(value);
    setAgencyState("");
  }

  const onChangeAccount = (value) => {
    setAccount(value);
    setAccountState("");
  }

  const onChangeCustomerName = (value) => {
    setCustomerName(value);
    setCustomerNameState("");
  }

  const onChangeReason = (value) => {
    setRefundReason(value);
    setRefundReasonState("");
  }

  return (
    <>
      <div className="content">
        <Col
          lg={{ size: 8, offset: 1 }}
          md={{ size: 10, offset: 1 }}
          sm={{ size: 10, offset: 1 }}
        >
          <Row>
          </Row>
          <Card className="card-user input-change">
            <CardBody>
              <Form>
                <Row>
                  <Col className="text-dark" md="7">
                    <FormGroup className="has-danger">
                      <label>Banco *</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={bankSelect}
                        onChange={(value) => onChangeBankSelect(value)}
                        options={banks}
                        placeholder=""
                      />{bankState === "has-danger" ? (
                        <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <FormGroup>
                      <label>Tipo de conta *</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={accountType}
                        onChange={(value) => onChangeAccountTypeSelect(value)}
                        options={[
                          { value: "1", label: "Conta corrente" },
                          { value: "2", label: "Conta corrente cojunta" },
                          { value: "3", label: "Conta poupança" },
                          { value: "4", label: "Conta poupança cojunta" }
                        ]}
                        placeholder=""
                      />
                      {accountTypeState === "has-danger" ? (
                        <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup className={`has-label ${agencyState}`}>
                      <label>Agência *</label>
                      <Input
                        value={agency}
                        type="text"
                        onChange={(e) => onChangeAgency(e.target.value)}
                      />
                      {agencyState === "has-danger" ? (
                        <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={`has-label ${accountState}`}>
                      <label>Conta *</label>
                      <Input
                        value={account}
                        type="text"
                        onChange={(e) => onChangeAccount(e.target.value)}
                      />
                      {accountState === "has-danger" ? (
                        <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <FormGroup>
                      <label>CPF/CNPJ *</label>
                      <Input
                        defaultValue={item.cpf}
                        disabled
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup className={`has-label ${customerNameState}`}>
                      <label>Nome completo/Razão social do favorecido *</label>
                      <Input
                        type="text"
                        value={customerName}
                        onChange={(e) => onChangeCustomerName(e.target.value)}
                      />
                      {customerNameState === "has-danger" ? (
                        <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label>Valor do estorno</label>
                      <Input
                        defaultValue={item.total_price.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={`has-label ${refundReasonState}`}>
                      <label>Motivo *</label>
                      <Input
                        className="textarea"
                        type="textarea"
                        cols="80"
                        rows="4"
                        value={refundReason}
                        onChange={(e) => onChangeReason(e.target.value)}
                      />
                      {refundReasonState === "has-danger" ? (
                        <label style={{ color: "#ef8157" }}>Esse campo é obrigatório.</label>
                      ) : null}
                    </FormGroup>
                    <div className="category form-category">
                      * Obrigatório
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter className="text-right">
              <Button
                onClick={submitHandler}
                disabled={isRefundLoading}
              >
                {isRefundLoading ? "Aguarde..." : "Reembolsar venda"}
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </div>
    </>
  );
}

export default OrderRefund;
