import { auth } from "./Firebase";
import api from "./api"

class BankAccounts {
  constructor() {
    this.token = null;
    this.bankAccounts = [];
  }

  getIdToken = async () => {
    const token = await auth.currentUser.getIdToken(true);
    this.token = token
  }

  getBankAccounts = async () => {
    if (!this.bankAccounts.length) {
      if (!this.token) {
        await this.getIdToken()
      }
      const response = await api.get("/bankAccountsByVendor", {
        params: {
          token: this.token,
        },
      });
      this.bankAccounts = response.data;
      return response.data;
    }
    return this.bankAccounts;
  }

  createBankAccount = async (bankAccount) => {
    if (!this.token) {
      await this.getIdToken()
    }
    const response = await api.post("/createBankAccount", bankAccount, {
      params: {
        token: this.token
      },
    });
    return response.data;
  }
}

export default class BankAccountsSingleton {
  constructor() {
    if (!BankAccountsSingleton.instance) {
      BankAccountsSingleton.instance = new BankAccounts();
    }
  }

  getInstance() {
    return BankAccountsSingleton.instance;
  }
}