/* !

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */
import React, { useState, useEffect } from "react";

// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
import "chartjs-plugin-labels";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Tooltip,
} from "reactstrap";

// core components
import { dashboardPieChart, dashboardLineChart, getPieChartColor } from "variables/charts.js";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isBrowser, isSafari } from "react-device-detect";

import { useCallback } from "react";
import VendorSingleton from "services/Vendor";
import FinanceByVendor from "../services/FinanceByVendor";
import Items from "../services/Items"
import { auth } from "services/Firebase";
import api from "services/api";
import fileDownload from "js-file-download";
import "../styles/card.css";

function Dashboard() {
  const [vendor, setVendor] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [netTotalPercentage, setNetTotalPercentage] = useState(0);
  const [netZeeploPercentage, setZeeploPercentage] = useState(0);
  const [netTaxPercentage, setTaxPercentage] = useState(0);
  const [items, setItems] = useState([]);
  const [isOpenTotalRevenue, setIsOpenTotalRevenue] = useState(false);
  const [isOpenNetTotal, setIsOpenNetTotal] = useState(false);
  const [isOpenRefundedTotal, setIsOpenRefundedTotal] = useState(false);
  const [isOpenOrdersTotal, setIsOpenOrdersTotal] = useState(false);
  const [isOpenOrdersPaid, setIsOpenOrdersPaid] = useState(false);
  const [isOpenOrdersWaiting, setIsOpenOrdersWaiting] = useState(false);
  const [isOpenOrdersRefunded, setIsOpenOrdersRefunded] = useState(false);
  const [isOpenOrdersCanceled, setIsOpenOrdersCanceled] = useState(false);
  const [isOpenOrdersExpired, setIsOpenOrdersExpired] = useState(false);
  const iconsColor = 'text-zeeplo-icons';

  const downloadCSV = useCallback(async () => {
    setIsCsvLoading(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      const responseVendor = await api.get("/vendor", { params: { token } });
      const vendor = responseVendor.data.vendor;
      const response = await api.get("/csvByVendor", {
        params: {
          token,
          initial_date: moment(initialDate).format("DD-MM-YYYY"),
          final_date: moment(finalDate).format("DD-MM-YYYY"),
        },
      });
      fileDownload(
        response.data,
        `Zeeplo_${vendor}_${moment().format("DD/MM/YYYY_TH:m")}.csv`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsCsvLoading(false);
    }
  }, [initialDate, finalDate]);

  const getVendorInfo = useCallback(async (initial_date, final_date) => {
    try {
      setIsLoading(true);

      const financeByVendorSingleton = new FinanceByVendor().getInstance();
      const financeByVendor = await financeByVendorSingleton.getFinanceByVendor(
        initial_date,
        final_date
      );
      const { comission, fee, hasFixedFee, name } = financeByVendor;
      try {localStorage.setItem('vendor', name)} catch (error) {}
      const vendorSingleton = new VendorSingleton().getInstance();
      vendorSingleton.setVendor({ comission, fee, hasFixedFee, name });
      setVendor(financeByVendor);
      setNetTotalPercentage(
        (
          (Number(financeByVendor?.net_total) /
            (Number(financeByVendor?.net_total) +
              Number(financeByVendor?.zeeplo_total) +
              Number(financeByVendor?.tax_total))) *
          100
        ).toFixed(2)
      );
      setZeeploPercentage(
        (
          (Number(financeByVendor?.zeeplo_total) /
            (Number(financeByVendor?.net_total) +
              Number(financeByVendor?.zeeplo_total) +
              Number(financeByVendor?.tax_total))) *
          100
        ).toFixed(2)
      );
      setTaxPercentage(
        (
          (Number(financeByVendor?.tax_total) /
            (Number(financeByVendor?.net_total) +
              Number(financeByVendor?.zeeplo_total) +
              Number(financeByVendor?.tax_total))) *
          100
        ).toFixed(2)
      );

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, []);

  async function getItemsInfo() {
    try {
      const itemsSingleton = new Items().getInstance();
      const itemList = await itemsSingleton.getItems().map(item => {
        return {
          value: item.id,
          label: item.title
        }
      });
      setItems(itemList);
    } catch (error) { }
  }

  useEffect(() => {
    if (isSafari && isBrowser) {
      const today = new Date();
      setInitialDate(today);
      setFinalDate(today);
    } else{
      setInitialDate(moment().format("YYYY-MM-DD"));
      setFinalDate(moment().format("YYYY-MM-DD"));
    }
    const today = moment().format("DD-MM-YYYY");
    getVendorInfo(today, today);
    getItemsInfo();
  }, [getVendorInfo]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (initialDate && finalDate) {
      const data = {
        initial_date: moment(initialDate).format("DD-MM-YYYY"),
        final_date: moment(finalDate).format("DD-MM-YYYY"),
      };

      getVendorInfo(data.initial_date, data.final_date);
    } else {
      getVendorInfo();
    }
  };

  const changeInitialDate = (e) => {
    setInitialDate(e.target.value);
  };

  const changeFinalDate = (e) => {
    setFinalDate(e.target.value);
  };

  const changeTitle = () => {
    document.querySelector(".navbar-brand").innerText = vendor.name;
  };

  const calculatePercentage = (numbers, index) => {
    let total = 0;
    numbers.forEach(element => {
      total += element.data;
    });
    return (numbers[index].data * 100 / total).toFixed(2);
  };

  const toggleTotalRevenue = () => {
    setIsOpenTotalRevenue(!isOpenTotalRevenue);
  };

  const toggleNetlTotal = () => {
    setIsOpenNetTotal(!isOpenNetTotal);
  };

  const toggleRefundedTotal = () => {
    setIsOpenRefundedTotal(!isOpenRefundedTotal);
  };

  const toggleOrdersTotal = () => {
    setIsOpenOrdersTotal(!isOpenOrdersTotal);
  };

  const toggleOrdersPaid = () => {
    setIsOpenOrdersPaid(!isOpenOrdersPaid);
  };

  const toggleOrdersWaiting = () => {
    setIsOpenOrdersWaiting(!isOpenOrdersWaiting);
  };

  const toggleOrdersRefunded = () => {
    setIsOpenOrdersRefunded(!isOpenOrdersRefunded);
  };

  const toggleOrdersCanceled = () => {
    setIsOpenOrdersCanceled(!isOpenOrdersCanceled);
  };

  const toggleOrdersExpired = () => {
    setIsOpenOrdersExpired(!isOpenOrdersExpired);
  };

  if (isLoading) {
    return (
      <div className="content">
        <div>Loading...</div>{" "}
      </div>
    );
  }

  return (
    <>
      {/* Checking which dashboard is visible, v2 or v1 */}
      {vendor.new_version ?
        // Dashboard v2
        <div className="content">
          {changeTitle()}
          <Form onSubmit={submitHandler}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg="4" md="4" sm="12" xs="12">
                    <FormGroup>
                      <label className="card-text">Data de Inicio:</label>
                      {isSafari && isBrowser ? (
                        <DatePicker
                          selected={initialDate}
                          onChange={setInitialDate}
                          customInput={<Input />}
                        />
                      ) : (
                        <Input
                          name="initial_date"
                          value={initialDate}
                          onChange={changeInitialDate}
                          type="date"
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4" md="4" sm="12" xs="12">
                    <FormGroup>
                      <label className="card-text">Data de Fim:</label>
                      {isSafari && isBrowser ? (
                        <DatePicker
                          selected={finalDate}
                          onChange={setFinalDate}
                          customInput={<Input />}
                        />
                      ) : (
                        <Input
                          name="final_date"
                          value={finalDate}
                          onChange={changeFinalDate}
                          type="date"
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4" md="4" sm="12" xs="12">
                    <br />
                    <Button
                      className="mb-0 btn-round btn-zeeplo-orange"
                      type="submit"
                    >
                      Consultar
                    </Button>
                    <Button
                      className="mb-0 btn-round btn-margin-left"
                      color="z-purple-1"
                      onClick={downloadCSV}
                      disabled={isCsvLoading}
                      outline
                    >
                      {isCsvLoading ? "Loading..." : <><i class="fa fa-file-export"></i> Exportar</>}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>
          <Row>
            <h5 className="pl-3">
              Receitas
            </h5>
          </Row>
          <Row>
            <Col lg="4" md="4" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-money-coins ${iconsColor}`} />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                          Receita Total Gerada{" "}
                          <Badge className="btn-zeeplo-light-gray" pill id="tooltipTotalRevenue">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={isOpenTotalRevenue} target="tooltipTotalRevenue" toggle={toggleTotalRevenue}>
                          Esse é o total de vendas que foram efetivamente pagas, incluindo reembolsos.
                        </Tooltip>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {vendor?.total_revenue?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="4" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-money-coins ${iconsColor}`} />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                          Ganho Líquido{" "}
                          <Badge  className="btn-zeeplo-light-gray" pill id="tooltipNetTotal">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={isOpenNetTotal} target="tooltipNetTotal" toggle={toggleNetlTotal}>
                          Seu lucro total, já com as taxas, comissões e reembolsos aplicados.
                        </Tooltip>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {vendor?.net_items_price_comission?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="4" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-refresh-69 ${iconsColor}`} />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                          Reembolsos{" "}
                          <Badge  className="btn-zeeplo-light-gray" pill id="tooltipRefundedTotal">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={isOpenRefundedTotal} target="tooltipRefundedTotal" toggle={toggleRefundedTotal}>
                          Essa é a soma de todas as vendas que foram reembolsadas.
                        </Tooltip>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {vendor?.refunded_value?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <h5 className="pl-3">
              Vendas
            </h5>
          </Row>
          <Row>
            <Col lg="4" md="4" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-chart-bar-32 ${iconsColor}`} />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                          Total Vendas{" "}
                          <Badge  className="btn-zeeplo-light-gray" pill id="tooltipOrdersTotal">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={isOpenOrdersTotal} target="tooltipOrdersTotal" toggle={toggleOrdersTotal}>
                          A quantidade de vendas realizadas, pagas ou não.
                        </Tooltip>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {vendor?.total_count}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="4" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-money-coins ${iconsColor}`} />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                          Pagas{" "}
                          <Badge  className="btn-zeeplo-light-gray" pill id="tooltipOrdersPaid">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={isOpenOrdersPaid} target="tooltipOrdersPaid" toggle={toggleOrdersPaid}>
                          Todas as vendas com status <b>Paga</b>.
                        </Tooltip>
                        <CardTitle tag="p" >
                          <span style={{ fontSize: 25 }}>
                            {vendor?.total_count_paid}
                          </span>
                          {' - '}
                          <span style={{ fontSize: 18 }}>
                            <b>{vendor?.count_paid_percent?.toFixed(2)}%</b>
                          </span>
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="4" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-paper ${iconsColor}`} />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                          Em Aberto{" "}
                          <Badge  className="btn-zeeplo-light-gray" pill id="tooltipOrdersWaiting">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={isOpenOrdersWaiting} target="tooltipOrdersWaiting" toggle={toggleOrdersWaiting}>
                          Boletos e PIX gerados, que ainda não foram pagos. De acordo com o banco, vendas em boleto podem demorar até 48h para constarem como <b>Pagas</b>.
                        </Tooltip>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {vendor?.waiting_payment}
                          </span>
                          {' - '}
                          <span style={{ fontSize: 18 }}>
                            <b>{vendor?.waiting_payment_percent?.toFixed(2)}%</b>
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {/* <Col lg="3" md="3" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-alert-circle-i text-zeeplo-blue" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category">
                          Abandonadas
                        </p>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 20 }}>
                            {vendor?.abandoned}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          <Row>
            <Col lg="4" md="4" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-refresh-69 ${iconsColor}`} />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                          Reembolsadas {" "}
                          <Badge  className="btn-zeeplo-light-gray" pill id="tooltipOrdersRefunded">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={isOpenOrdersRefunded} target="tooltipOrdersRefunded" toggle={toggleOrdersRefunded}>
                          Todas as vendas que foram reembolsadas.
                        </Tooltip>
                        <CardTitle tag="p" >
                          <span style={{ fontSize: 25 }}>
                            {vendor?.refunded}
                          </span>
                          {' - '}
                          <span style={{ fontSize: 18 }}>
                            <b>{vendor?.refunded_percent?.toFixed(2)}%</b>
                          </span>
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="4" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-alert-circle-i ${iconsColor}`} />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                          Canceladas {" "}
                          <Badge  className="btn-zeeplo-light-gray" pill id="tooltipOrdersCanceled">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={isOpenOrdersCanceled} target="tooltipOrdersCanceled" toggle={toggleOrdersCanceled}>
                          Vendas em cartão de crédito que <b>não</b> foram pagas por falta de limite ou recusadas pela operadora do cartão.
                        </Tooltip>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {vendor?.canceled}
                          </span>
                          {' - '}
                          <span style={{ fontSize: 18 }}>
                            <b>{vendor?.canceled_percent?.toFixed(2)}%</b>
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="4" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className={`nc-icon nc-calendar-60 ${iconsColor}`} />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category" style={{ cursor: 'default' }}>
                          Expiradas {" "}
                          <Badge  className="btn-zeeplo-light-gray" pill id="tooltipOrdersExpired">
                            <b>?</b>
                          </Badge>
                        </p>
                        <Tooltip placement="top" isOpen={isOpenOrdersExpired} target="tooltipOrdersExpired" toggle={toggleOrdersExpired}>
                          Boletos e PIX não pagos antes da data de vencimento.
                        </Tooltip>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 25 }}>
                            {vendor?.expired}
                          </span>
                          {' - '}
                          <span style={{ fontSize: 18 }}>
                            <b>{vendor?.expired_percent?.toFixed(2)}%</b>
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col
              md="6"
              className="d-flex"
              style={{
                flex: 1,
              }}
            >
              <Card className="w-100">
                <CardHeader>
                  <CardHeader>
                    <CardTitle tag="h5">Formas de Pagamento</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Pie
                      data={dashboardPieChart(vendor?.pieChart, vendor.new_version).data}
                      options={
                        dashboardPieChart().options
                      }
                    />
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="legend">
                      {vendor?.pieChart
                        .map((slice, index) => {
                          return <>
                            <div>
                              <i className={`fa fa-circle text-zeeplo-${getPieChartColor(index)}`} /> {slice.label ? slice.label : 'Outros'}
                              <br></br> <i className={`fa fa-circle text-zeeplo-${getPieChartColor(index)}`} />
                              <span>
                                {" "}
                                {slice.data}
                                {"  - "}
                                {calculatePercentage(vendor?.pieChart, index) + "%"}
                              </span>
                            </div>
                            <br></br>
                          </>
                        })
                      }
                    </div>
                  </CardFooter>
                </CardHeader>
              </Card>
            </Col>
            <Col
              md="6"
              className="d-flex"
              style={{
                flex: 1,
              }}
            >
              <Card className="card-chart w-100">
                <CardHeader>
                  <CardTitle tag="h5">Evolução Diária</CardTitle>
                </CardHeader>
                <CardBody>
                  <Line
                    data={dashboardLineChart(vendor.labels, vendor.values).data}
                    options={
                      dashboardLineChart(vendor.labels, vendor.values).options
                    }
                    width={400}
                    height={200}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        :
        // Dashboard v1
        <div className="content">
          {changeTitle()}
          <Form onSubmit={submitHandler}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg="4" md="4" sm="12" xs="12">
                    <FormGroup>
                      <label className="card-text">Data de Inicio:</label>
                      {isSafari && isBrowser ? (
                        <DatePicker
                          selected={initialDate}
                          onChange={setInitialDate}
                          customInput={<Input />}
                        />
                      ) : (
                        <Input
                          name="initial_date"
                          value={initialDate}
                          onChange={changeInitialDate}
                          type="date"
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4" md="4" sm="12" xs="12">
                    <FormGroup>
                      <label className="card-text">Data de Fim:</label>
                      {isSafari && isBrowser ? (
                        <DatePicker
                          selected={finalDate}
                          onChange={setFinalDate}
                          customInput={<Input />}
                        />
                      ) : (
                        <Input
                          name="final_date"
                          value={finalDate}
                          onChange={changeFinalDate}
                          type="date"
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4" md="4" sm="12" xs="12">
                    <br />
                    <Button
                      className="mb-0 btn-round btn-zeeplo-orange"
                      type="submit"
                    >
                      Consultar
                    </Button>
                    <Button
                      className="mb-0 btn-round btn-margin-left"
                      color="z-purple-1"
                      onClick={downloadCSV}
                      disabled={isCsvLoading}
                    >
                      {isCsvLoading ? "Loading..." : <><i class="fa fa-file-export"></i> Exportar</>}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>

          <Row>
            <Col lg="6" md="8" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-money-coins text-zeeplo-blue" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category">Faturamento</p>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 20 }}>
                            {vendor?.gross_total?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" md="8" sm="12" xs="12">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-chart-bar-32 text-zeeplo-blue" />
                      </div>
                    </Col>
                    <Col md="9" xs="8">
                      <div className="numbers">
                        <p className="card-category">
                          Número total de vendas
                        </p>
                        <CardTitle tag="p">
                          <span style={{ fontSize: 20 }}>
                            {vendor?.total_count}
                          </span>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col
              md="6"
              className="d-flex"
              style={{
                flex: 1,
              }}
            >
              <Card className="w-100">
                <CardHeader>
                  <CardHeader>
                    <CardTitle tag="h5">Distribuição</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Pie
                      data={
                        dashboardPieChart([
                          { data: vendor?.tax_total, label: "Taxas de Transação" },
                          { data: vendor?.zeeplo_total, label: "Zeeplo" },
                          { data: vendor?.net_total, label: "Líquido" }]
                        ).data
                      }
                      options={
                        dashboardPieChart().options
                      }
                    />
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="legend">
                      {
                        [{ data: vendor?.net_total, label: "Líquido", backgroundColor: "orange", percentage: netTotalPercentage },
                        { data: vendor?.zeeplo_total, label: "Zeeplo", backgroundColor: "light-gray", percentage: netZeeploPercentage },
                        { data: vendor?.tax_total, label: vendor.hasFixedFee ? "Juros pagos pelo cliente" : "Taxa de transação", backgroundColor: "dark-gray", percentage: netTaxPercentage }]
                          .map(slice => {
                            return <>
                              <div>
                                <i className={`fa fa-circle text-zeeplo-${slice.backgroundColor}`} /> {slice.label}
                                <br></br> <i className={`fa fa-circle text-zeeplo-${slice.backgroundColor}`} />
                                <span>
                                  {" "}
                                  {slice.data?.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                  {"  - "}
                                  {slice.percentage + "%"}
                                </span>
                              </div>
                              <br></br>
                            </>
                          })
                      }
                    </div>
                  </CardFooter>
                </CardHeader>
              </Card>
            </Col>
            <Col
              md="6"
              className="d-flex"
              style={{
                flex: 1,
              }}
            >
              <Card className="card-chart w-100">
                <CardHeader>
                  <CardTitle tag="h5">Evolução Diária</CardTitle>
                </CardHeader>
                <CardBody>
                  <Line
                    data={dashboardLineChart(vendor.labels, vendor.values).data}
                    options={
                      dashboardLineChart(vendor.labels, vendor.values).options
                    }
                    width={400}
                    height={200}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>}

    </>
  );
}

export default Dashboard;
