import React, { useEffect, useState, useCallback } from "react";
import { useRouteMatch } from "react-router";
import { Link } from 'react-router-dom';
import {
  Col,
  Card,
  CardBody,
  Row,
} from "reactstrap";


function SettingCampaigns() {
  const { url } = useRouteMatch();
  useEffect(() => {

  }, []);

  return (
    <>
      <div className="content">
        <Row>
          <h5 className="pl-3">
            Integração Marketing
          </h5>
        </Row>
        <Row>
          <Col lg="4" md="4" sm="12" xs="12">
            <Card className="card-stats">
            <Link to={`${url}/activeCampaing`} style={{ textDecoration: 'none', color: '#000' }}>
              <CardBody style={{height: 80}} >
                <Row>
                  <Col md="12" xs="12">
                    <Row className="align-items-center">
                      <Col lg="2" md="2" sm="12" xs="12">
                        <img src={require("../assets/img/AC_Glyph_Blue.svg")} alt="" style={{ width: '56px', height: '56px'}} />
                      </Col>
                      <Col lg="6" md="6" sm="12" xs="12" style={{alignItems: 'left'}}>
                        <h6>activeCampaing</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg="4" md="4" sm="12" xs="12">
            <Card className="card-stats">
            <Link to={`${url}/googleads`} style={{ textDecoration: 'none', color: '#000' }}>
            <CardBody style={{height: 80}}>
                <Row>
                  <Col md="12" xs="12">
                    <Row className="align-items-center">
                      <Col lg="2" md="2" sm="12" xs="12">
                        <img src={require("../assets/img/google-ads-svgrepo-com.svg")} alt="logo-small" style={{ width: '56px', height: '56px'}} />
                      </Col>
                      <Col lg="6" md="6" sm="12" xs="12" style={{alignItems: 'left'}}>
                        <h6>Google Ads</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              </Link>
            </Card>
          </Col>
          <Col lg="4" md="4" sm="12" xs="12">
            <Card className="card-stats">
            <Link to={`${url}/convertkit`} style={{ textDecoration: 'none', color: '#000' }}>
            <CardBody style={{height: 80}}>
                <Row>
                  <Col md="12" xs="12">
                    <Row className="align-items-center">
                      <Col lg="2" md="2" sm="12" xs="12">
                        <img src={require("../assets/img/convertkit.svg")} alt="logo-small" style={{ width: '56px', height: '56px'}} />
                      </Col>
                      <Col lg="6" md="6" sm="12" xs="12" style={{alignItems: 'left'}}>
                        <h6>ConvertKit</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              </Link>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="4" sm="12" xs="12">
            <Card className="card-stats">
            <a href="https://docs.google.com/document/d/1kTCrngqi9g-V_-iQHV1lktpUTvixdCLKq1yQG8zc4jQ/edit" 
             style={{ textDecoration: 'none', color: '#000' }}>
              <CardBody style={{height: 80}}>
                <Row>
                  <Col md="12" xs="12">
                    <Row className="align-items-center">
                      <Col lg="2" md="2" sm="12" xs="12">
                        <img src={require("../assets/img/facebook-2020-2-1.svg")} alt="logo-small" style={{ width: '56px', height: '56px'}} />
                      </Col>
                      <Col lg="6" md="6" sm="12" xs="12" style={{alignItems: 'left'}}>
                        <h6>Facebook Marketing</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              </a>
            </Card>
          </Col>
        </Row>
      </div>

    </>
  );
}

export default SettingCampaigns;