/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

const colors = [ "#FA7271", "#46457E", "#FC8E58", "#ECB1BB", "#DDECFC", "#3D2C70"];
const colorsVariables = ["chart1", "chart2", "chart3", "chart4", "chart5", "chart6"];
const colorsOldVersion = ["#404040", "#B0B0B0", "#f37029"];

const dashboard24HoursPerformanceChart = (labels, data) => ({
  data: (canvas) => {
    return {
      labels,
      datasets: [
        {
          borderColor: "#6bd098",
          backgroundColor: "#6bd098",
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 2,
          data,
        }
      ],
    };
  },
  options: {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },

    tooltips: {
      enabled: false,
    },

    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "#ccc",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],

      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
            display: false,
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
          },
        },
      ],
    },
  },
});

const dashboardPieChart = (slices, newVersion = false) => ({
  data: (canvas) => {
    return {
      labels: slices.map(slice => slice.label ? slice.label : 'Outros'),
      datasets: [
        {
          label: "Distribuição do Faturamento",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: newVersion ? colors : colorsOldVersion,
          borderWidth: 1.2,
          borderColor: 'white',
          data: slices.map(slice => slice.data?.toFixed(3))
        },
      ],
    };
  },
  options: {
    legend: {
      display: true,
    },
    plugins: {

      labels: {
        render: 'percentage',
        textMargin: 0,
        outsidePadding: 0,
        position: 'inside',
        fontFamily: "'Montserrat', 'Helvetica Neue',  Arial, sans-serif",
        fontStyle: 'normal',
        fontSize: 10,
        precision: 2,
        fontColor: 'rgba(255,255,255,0.05)'
      }

    },

    tooltips: {
      enabled: false,
    },

    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],

      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  },
});

const dashboardLineChart = (labels, data) => ({
  data: {
    labels,
    datasets: [
      {
        data,
        fill: false,
        borderColor: "#46457E",
        backgroundColor: "transparent",
        pointBorderColor: "#46457E",
        pointRadius: 0,
        pointHoverRadius: 4,
        pointBorderWidth: 8,

      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: "top"
    }

  },
});

const getPieChartColor = (index) => {
  return colorsVariables[index];
};

module.exports = {
  dashboard24HoursPerformanceChart,
  dashboardPieChart,
  dashboardLineChart,
  getPieChartColor,
};
