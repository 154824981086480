import { auth } from "./Firebase";
import api from "./api";

class OrdersByItem {
  constructor() {
    this.token = null;
    this.initial_date = null;
    this.final_date = null;

    this.dashboard = [];
  }

  getIdToken = async () => {
    const token = await auth.currentUser.getIdToken(true);
    this.token = token;
  };

  getOrdersByItem = async (itemId, initial_date, final_date) => {
    if (this.initial_date !== initial_date && this.final_date !== final_date) {
      if (!this.token) {
        await this.getIdToken();
      }

      const response = await api.get("/ordersByVendor", {
        params: {
          token: this.token,
          initial_date,
          final_date,
        },
      });

      this.initial_date = initial_date;
      this.final_date = final_date;
      this.itemId = itemId;
      this.dashboard = response.data;

      return response.data.filter((item) => {
        return item.itemId.toString() === itemId.toString();
      });
    }

    return this.dashboard.filter((item) => {
      return item.itemId.toString() === itemId.toString();
    });
  };
}

export default class OrdersByItemSingleton {
  constructor() {
    if (!OrdersByItemSingleton.instance) {
      OrdersByItemSingleton.instance = new OrdersByItem();
    }
  }

  getInstance() {
    return OrdersByItemSingleton.instance;
  }
}
